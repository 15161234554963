/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */
import type {
  AddAuctionFavoriteFolderItemQueryModel,
  AlarmDanjiInputDto,
  AlarmInputDto,
  ApiResponse,
  AppLoginLogoutModel,
  AuctionFavoriteFolderResultCode,
  AuctionFilterAllDto,
  AuctionFilterDto,
  BjdInfoDto,
  CreateAuctionFavoriteFolderQueryModel,
  CreateAuctionFilterQueryModel,
  DanjiVisitModel,
  DeleteNhFavoriteInputDto,
  DeleteUserLogoutParams,
  DeleteUserV2WithdrawParams,
  EncryptUserIdDto,
  FavoriteCodeEnum,
  FavoriteDanjiInputDto,
  FavoriteDanjiListDto,
  FavoritePlaceModel,
  FavoriteTotalDto,
  FeedActivityDto,
  FeedAlarmDto,
  FeedSubscriptionDto,
  FeedSubscriptionReqDto,
  GetUserDeviceConfigKeyParams,
  GetUserDeviceNotificationIdParams,
  GetUserDeviceNotificationNewParams,
  GetUserDeviceTermsMarketingParams,
  GetUserFavoriteSummaryListParams,
  GetUserNhInternalTokenParams,
  GetUserNotificationNewParams,
  GetUserNotificationParams,
  GetUserNotificationR2Params,
  GetUserNotificationV2Params,
  GetUserSamsungTokenParams,
  GetUserSignalCommentParams,
  InsertNhFavoriteInputDto,
  NhFavoritesDto,
  NhRequestDto,
  NhResponseDto,
  PostUserConfigKeyParams,
  PostUserDeviceConfigKeyParams,
  PostUserDeviceTermsTernNameAgreeYnParams,
  PostUserMobileTokenTokenParams,
  PushClickEventModel,
  PutRecordResponse,
  PutUserDeviceDeviceIdParams,
  PutUserRealestateInfoParams,
  ReferralCategoryType,
  RemoveAuctionFavoriteFolderItemQueryModel,
  ResiterUserDto,
  SignalBjdAddReqDto,
  SignalBjdDto,
  SignalCommentDto,
  SignalCommentReqAddDto,
  SignalCommentReqLikeDto,
  SignalCommentReqModifyDto,
  SignalVoteDto,
  SignalVoteReqDto,
  SignalVoteSimpleDto,
  UpdateAuctionFavoriteFolderQueryModel,
  UpdateAuctionFilterQueryModel,
  UpdateUserDto,
  UserFavoriteDanjiSummaryDto,
  UserIpDto,
  UserPlaceLogModel,
  UserV2Dto,
  VerifyRealtyOwnerModel,
} from '../../model';
import { customFetch } from '../../mutator/customFetch';
import type { BodyType, ErrorType } from '../../mutator/customFetch';

import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 앱 내에서 푸시 클릭 이벤트
 */
export const postUserPushClick = (
  pushClickEventModel: BodyType<PushClickEventModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<PutRecordResponse>(
    {
      url: '/user/push/click',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: pushClickEventModel,
    },
    options
  );
};

export const getPostUserPushClickMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserPushClick>>,
    TError,
    { data: BodyType<PushClickEventModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserPushClick>>,
  TError,
  { data: BodyType<PushClickEventModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserPushClick>>,
    { data: BodyType<PushClickEventModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserPushClick(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserPushClickMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserPushClick>>
>;
export type PostUserPushClickMutationBody = BodyType<PushClickEventModel>;
export type PostUserPushClickMutationError = ErrorType<unknown>;

/**
 * @summary 앱 내에서 푸시 클릭 이벤트
 */
export const usePostUserPushClick = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserPushClick>>,
    TError,
    { data: BodyType<PushClickEventModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserPushClick>>,
  TError,
  { data: BodyType<PushClickEventModel> },
  TContext
> => {
  const mutationOptions = getPostUserPushClickMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserSamsungToken = (
  params?: GetUserSamsungTokenParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: '/user/samsung/token', method: 'GET', params, signal },
    options
  );
};

export const getGetUserSamsungTokenQueryKey = (params?: GetUserSamsungTokenParams) => {
  return ['/user/samsung/token', ...(params ? [params] : [])] as const;
};

export const getGetUserSamsungTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSamsungToken>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserSamsungTokenParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSamsungToken>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSamsungTokenQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSamsungToken>>> = ({ signal }) =>
    getUserSamsungToken(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSamsungToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSamsungTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSamsungToken>>
>;
export type GetUserSamsungTokenQueryError = ErrorType<unknown>;

export const useGetUserSamsungToken = <
  TData = Awaited<ReturnType<typeof getUserSamsungToken>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserSamsungTokenParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSamsungToken>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSamsungTokenQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 시그널 댓글 작성
 */
export const postUserSignalComment = (
  signalCommentReqAddDto: BodyType<SignalCommentReqAddDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<SignalCommentDto>(
    {
      url: '/user/signal/comment',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: signalCommentReqAddDto,
    },
    options
  );
};

export const getPostUserSignalCommentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSignalComment>>,
    TError,
    { data: BodyType<SignalCommentReqAddDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserSignalComment>>,
  TError,
  { data: BodyType<SignalCommentReqAddDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserSignalComment>>,
    { data: BodyType<SignalCommentReqAddDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserSignalComment(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserSignalCommentMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserSignalComment>>
>;
export type PostUserSignalCommentMutationBody = BodyType<SignalCommentReqAddDto>;
export type PostUserSignalCommentMutationError = ErrorType<unknown>;

/**
 * @summary 시그널 댓글 작성
 */
export const usePostUserSignalComment = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSignalComment>>,
    TError,
    { data: BodyType<SignalCommentReqAddDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserSignalComment>>,
  TError,
  { data: BodyType<SignalCommentReqAddDto> },
  TContext
> => {
  const mutationOptions = getPostUserSignalCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 시그널 댓글 수정
 */
export const patchUserSignalComment = (
  signalCommentReqModifyDto: BodyType<SignalCommentReqModifyDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<SignalCommentDto>(
    {
      url: '/user/signal/comment',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: signalCommentReqModifyDto,
    },
    options
  );
};

export const getPatchUserSignalCommentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserSignalComment>>,
    TError,
    { data: BodyType<SignalCommentReqModifyDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUserSignalComment>>,
  TError,
  { data: BodyType<SignalCommentReqModifyDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUserSignalComment>>,
    { data: BodyType<SignalCommentReqModifyDto> }
  > = (props) => {
    const { data } = props ?? {};

    return patchUserSignalComment(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchUserSignalCommentMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUserSignalComment>>
>;
export type PatchUserSignalCommentMutationBody = BodyType<SignalCommentReqModifyDto>;
export type PatchUserSignalCommentMutationError = ErrorType<unknown>;

/**
 * @summary 시그널 댓글 수정
 */
export const usePatchUserSignalComment = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserSignalComment>>,
    TError,
    { data: BodyType<SignalCommentReqModifyDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchUserSignalComment>>,
  TError,
  { data: BodyType<SignalCommentReqModifyDto> },
  TContext
> => {
  const mutationOptions = getPatchUserSignalCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 시그널 댓글 최근 90일 조회
 */
export const getUserSignalComment = (
  params?: GetUserSignalCommentParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<SignalCommentDto[]>(
    { url: '/user/signal/comment', method: 'GET', params, signal },
    options
  );
};

export const getGetUserSignalCommentQueryKey = (params?: GetUserSignalCommentParams) => {
  return ['/user/signal/comment', ...(params ? [params] : [])] as const;
};

export const getGetUserSignalCommentQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSignalComment>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserSignalCommentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalComment>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSignalCommentQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSignalComment>>> = ({ signal }) =>
    getUserSignalComment(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSignalComment>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSignalCommentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSignalComment>>
>;
export type GetUserSignalCommentQueryError = ErrorType<unknown>;

/**
 * @summary 시그널 댓글 최근 90일 조회
 */
export const useGetUserSignalComment = <
  TData = Awaited<ReturnType<typeof getUserSignalComment>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserSignalCommentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalComment>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSignalCommentQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 시그널 댓글 삭제
 */
export const deleteUserSignalCommentCommentId = (
  commentId: number,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<SignalCommentDto>(
    { url: `/user/signal/comment/${commentId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteUserSignalCommentCommentIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserSignalCommentCommentId>>,
    TError,
    { commentId: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserSignalCommentCommentId>>,
  TError,
  { commentId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserSignalCommentCommentId>>,
    { commentId: number }
  > = (props) => {
    const { commentId } = props ?? {};

    return deleteUserSignalCommentCommentId(commentId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserSignalCommentCommentIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserSignalCommentCommentId>>
>;

export type DeleteUserSignalCommentCommentIdMutationError = ErrorType<unknown>;

/**
 * @summary 시그널 댓글 삭제
 */
export const useDeleteUserSignalCommentCommentId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserSignalCommentCommentId>>,
    TError,
    { commentId: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserSignalCommentCommentId>>,
  TError,
  { commentId: number },
  TContext
> => {
  const mutationOptions = getDeleteUserSignalCommentCommentIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 시그널 베스트 댓글 조회
 */
export const getUserSignalCommentBest = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<SignalCommentDto[]>(
    { url: '/user/signal/comment/best', method: 'GET', signal },
    options
  );
};

export const getGetUserSignalCommentBestQueryKey = () => {
  return ['/user/signal/comment/best'] as const;
};

export const getGetUserSignalCommentBestQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSignalCommentBest>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalCommentBest>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSignalCommentBestQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSignalCommentBest>>> = ({ signal }) =>
    getUserSignalCommentBest(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSignalCommentBest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSignalCommentBestQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSignalCommentBest>>
>;
export type GetUserSignalCommentBestQueryError = ErrorType<unknown>;

/**
 * @summary 시그널 베스트 댓글 조회
 */
export const useGetUserSignalCommentBest = <
  TData = Awaited<ReturnType<typeof getUserSignalCommentBest>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalCommentBest>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSignalCommentBestQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 시그널 댓글 좋아요
 */
export const postUserSignalCommentLike = (
  signalCommentReqLikeDto: BodyType<SignalCommentReqLikeDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<SignalCommentDto>(
    {
      url: '/user/signal/comment/like',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: signalCommentReqLikeDto,
    },
    options
  );
};

export const getPostUserSignalCommentLikeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSignalCommentLike>>,
    TError,
    { data: BodyType<SignalCommentReqLikeDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserSignalCommentLike>>,
  TError,
  { data: BodyType<SignalCommentReqLikeDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserSignalCommentLike>>,
    { data: BodyType<SignalCommentReqLikeDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserSignalCommentLike(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserSignalCommentLikeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserSignalCommentLike>>
>;
export type PostUserSignalCommentLikeMutationBody = BodyType<SignalCommentReqLikeDto>;
export type PostUserSignalCommentLikeMutationError = ErrorType<unknown>;

/**
 * @summary 시그널 댓글 좋아요
 */
export const usePostUserSignalCommentLike = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSignalCommentLike>>,
    TError,
    { data: BodyType<SignalCommentReqLikeDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserSignalCommentLike>>,
  TError,
  { data: BodyType<SignalCommentReqLikeDto> },
  TContext
> => {
  const mutationOptions = getPostUserSignalCommentLikeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 시그널 투표하기
 */
export const postUserSignalVote = (
  signalVoteReqDto: BodyType<SignalVoteReqDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<SignalVoteDto>(
    {
      url: '/user/signal/vote',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: signalVoteReqDto,
    },
    options
  );
};

export const getPostUserSignalVoteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSignalVote>>,
    TError,
    { data: BodyType<SignalVoteReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserSignalVote>>,
  TError,
  { data: BodyType<SignalVoteReqDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserSignalVote>>,
    { data: BodyType<SignalVoteReqDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserSignalVote(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserSignalVoteMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserSignalVote>>
>;
export type PostUserSignalVoteMutationBody = BodyType<SignalVoteReqDto>;
export type PostUserSignalVoteMutationError = ErrorType<unknown>;

/**
 * @summary 시그널 투표하기
 */
export const usePostUserSignalVote = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSignalVote>>,
    TError,
    { data: BodyType<SignalVoteReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserSignalVote>>,
  TError,
  { data: BodyType<SignalVoteReqDto> },
  TContext
> => {
  const mutationOptions = getPostUserSignalVoteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 시그널 전체 투표 id
 */
export const getUserSignalVotes = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<SignalVoteSimpleDto[]>(
    { url: '/user/signal/votes', method: 'GET', signal },
    options
  );
};

export const getGetUserSignalVotesQueryKey = () => {
  return ['/user/signal/votes'] as const;
};

export const getGetUserSignalVotesQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSignalVotes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalVotes>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSignalVotesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSignalVotes>>> = ({ signal }) =>
    getUserSignalVotes(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSignalVotes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSignalVotesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSignalVotes>>
>;
export type GetUserSignalVotesQueryError = ErrorType<unknown>;

/**
 * @summary 시그널 전체 투표 id
 */
export const useGetUserSignalVotes = <
  TData = Awaited<ReturnType<typeof getUserSignalVotes>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalVotes>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSignalVotesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 시그널 특정 투표 득표 정보 얻기
 */
export const getUserSignalVoteVoteId = (
  voteId: number,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<SignalVoteDto>(
    { url: `/user/signal/vote/${voteId}`, method: 'GET', signal },
    options
  );
};

export const getGetUserSignalVoteVoteIdQueryKey = (voteId: number) => {
  return [`/user/signal/vote/${voteId}`] as const;
};

export const getGetUserSignalVoteVoteIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSignalVoteVoteId>>,
  TError = ErrorType<unknown>
>(
  voteId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalVoteVoteId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSignalVoteVoteIdQueryKey(voteId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSignalVoteVoteId>>> = ({ signal }) =>
    getUserSignalVoteVoteId(voteId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!voteId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSignalVoteVoteId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSignalVoteVoteIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSignalVoteVoteId>>
>;
export type GetUserSignalVoteVoteIdQueryError = ErrorType<unknown>;

/**
 * @summary 시그널 특정 투표 득표 정보 얻기
 */
export const useGetUserSignalVoteVoteId = <
  TData = Awaited<ReturnType<typeof getUserSignalVoteVoteId>>,
  TError = ErrorType<unknown>
>(
  voteId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalVoteVoteId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSignalVoteVoteIdQueryOptions(voteId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 시그널 지역 추가하기
 */
export const postUserSignalBjd = (
  signalBjdAddReqDto: BodyType<SignalBjdAddReqDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<SignalBjdDto[]>(
    {
      url: '/user/signal/bjd',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: signalBjdAddReqDto,
    },
    options
  );
};

export const getPostUserSignalBjdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSignalBjd>>,
    TError,
    { data: BodyType<SignalBjdAddReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserSignalBjd>>,
  TError,
  { data: BodyType<SignalBjdAddReqDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserSignalBjd>>,
    { data: BodyType<SignalBjdAddReqDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserSignalBjd(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserSignalBjdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserSignalBjd>>
>;
export type PostUserSignalBjdMutationBody = BodyType<SignalBjdAddReqDto>;
export type PostUserSignalBjdMutationError = ErrorType<unknown>;

/**
 * @summary 시그널 지역 추가하기
 */
export const usePostUserSignalBjd = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSignalBjd>>,
    TError,
    { data: BodyType<SignalBjdAddReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserSignalBjd>>,
  TError,
  { data: BodyType<SignalBjdAddReqDto> },
  TContext
> => {
  const mutationOptions = getPostUserSignalBjdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 시그널 지역 내용 수정하기
 */
export const patchUserSignalBjd = (
  signalBjdAddReqDto: BodyType<SignalBjdAddReqDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<SignalBjdDto[]>(
    {
      url: '/user/signal/bjd',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: signalBjdAddReqDto,
    },
    options
  );
};

export const getPatchUserSignalBjdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserSignalBjd>>,
    TError,
    { data: BodyType<SignalBjdAddReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUserSignalBjd>>,
  TError,
  { data: BodyType<SignalBjdAddReqDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUserSignalBjd>>,
    { data: BodyType<SignalBjdAddReqDto> }
  > = (props) => {
    const { data } = props ?? {};

    return patchUserSignalBjd(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchUserSignalBjdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUserSignalBjd>>
>;
export type PatchUserSignalBjdMutationBody = BodyType<SignalBjdAddReqDto>;
export type PatchUserSignalBjdMutationError = ErrorType<unknown>;

/**
 * @summary 시그널 지역 내용 수정하기
 */
export const usePatchUserSignalBjd = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserSignalBjd>>,
    TError,
    { data: BodyType<SignalBjdAddReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchUserSignalBjd>>,
  TError,
  { data: BodyType<SignalBjdAddReqDto> },
  TContext
> => {
  const mutationOptions = getPatchUserSignalBjdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 내 시그널 지역 내용 조회하기
 */
export const getUserSignalBjd = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<SignalBjdDto[]>({ url: '/user/signal/bjd', method: 'GET', signal }, options);
};

export const getGetUserSignalBjdQueryKey = () => {
  return ['/user/signal/bjd'] as const;
};

export const getGetUserSignalBjdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSignalBjd>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalBjd>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSignalBjdQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSignalBjd>>> = ({ signal }) =>
    getUserSignalBjd(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSignalBjd>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSignalBjdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserSignalBjd>>>;
export type GetUserSignalBjdQueryError = ErrorType<unknown>;

/**
 * @summary 내 시그널 지역 내용 조회하기
 */
export const useGetUserSignalBjd = <
  TData = Awaited<ReturnType<typeof getUserSignalBjd>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalBjd>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSignalBjdQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 시그널 지역 내용 삭제하기
 */
export const deleteUserSignalBjdBjdCode = (
  bjdCode: string,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<SignalBjdDto[]>(
    { url: `/user/signal/bjd/${bjdCode}`, method: 'DELETE' },
    options
  );
};

export const getDeleteUserSignalBjdBjdCodeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserSignalBjdBjdCode>>,
    TError,
    { bjdCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserSignalBjdBjdCode>>,
  TError,
  { bjdCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserSignalBjdBjdCode>>,
    { bjdCode: string }
  > = (props) => {
    const { bjdCode } = props ?? {};

    return deleteUserSignalBjdBjdCode(bjdCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserSignalBjdBjdCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserSignalBjdBjdCode>>
>;

export type DeleteUserSignalBjdBjdCodeMutationError = ErrorType<unknown>;

/**
 * @summary 시그널 지역 내용 삭제하기
 */
export const useDeleteUserSignalBjdBjdCode = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserSignalBjdBjdCode>>,
    TError,
    { bjdCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserSignalBjdBjdCode>>,
  TError,
  { bjdCode: string },
  TContext
> => {
  const mutationOptions = getDeleteUserSignalBjdBjdCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 최근 방문한 지역 중 시그널 등록되지 않은 지역 목록
 */
export const getUserSignalBjdNoSignal = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<BjdInfoDto[]>(
    { url: '/user/signal/bjd/no-signal', method: 'GET', signal },
    options
  );
};

export const getGetUserSignalBjdNoSignalQueryKey = () => {
  return ['/user/signal/bjd/no-signal'] as const;
};

export const getGetUserSignalBjdNoSignalQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSignalBjdNoSignal>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalBjdNoSignal>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSignalBjdNoSignalQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSignalBjdNoSignal>>> = ({ signal }) =>
    getUserSignalBjdNoSignal(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSignalBjdNoSignal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSignalBjdNoSignalQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSignalBjdNoSignal>>
>;
export type GetUserSignalBjdNoSignalQueryError = ErrorType<unknown>;

/**
 * @summary 최근 방문한 지역 중 시그널 등록되지 않은 지역 목록
 */
export const useGetUserSignalBjdNoSignal = <
  TData = Awaited<ReturnType<typeof getUserSignalBjdNoSignal>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSignalBjdNoSignal>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSignalBjdNoSignalQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 15자리 랜덤 닉네임 (실패시) 바코드 닉네임 (실패시) 공백
 * @summary 랜덤 닉네임
 */
export const getUserNicknameRandom = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>({ url: '/user/nickname/random', method: 'GET', signal }, options);
};

export const getGetUserNicknameRandomQueryKey = () => {
  return ['/user/nickname/random'] as const;
};

export const getGetUserNicknameRandomQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserNicknameRandom>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNicknameRandom>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNicknameRandomQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNicknameRandom>>> = ({ signal }) =>
    getUserNicknameRandom(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserNicknameRandom>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserNicknameRandomQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserNicknameRandom>>
>;
export type GetUserNicknameRandomQueryError = ErrorType<unknown>;

/**
 * @summary 랜덤 닉네임
 */
export const useGetUserNicknameRandom = <
  TData = Awaited<ReturnType<typeof getUserNicknameRandom>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNicknameRandom>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserNicknameRandomQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 정책 리스트
 */
export const getUserTerms = (options?: SecondParameter<typeof customFetch>, signal?: AbortSignal) => {
  return customFetch<ApiResponse>({ url: '/user/terms', method: 'GET', signal }, options);
};

export const getGetUserTermsQueryKey = () => {
  return ['/user/terms'] as const;
};

export const getGetUserTermsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserTerms>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserTerms>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserTermsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserTerms>>> = ({ signal }) =>
    getUserTerms(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserTerms>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserTermsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserTerms>>>;
export type GetUserTermsQueryError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary 정책 리스트
 */
export const useGetUserTerms = <
  TData = Awaited<ReturnType<typeof getUserTerms>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserTerms>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserTermsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postUserV2Register = (
  resiterUserDto: BodyType<ResiterUserDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/V2/register',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: resiterUserDto,
    },
    options
  );
};

export const getPostUserV2RegisterMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserV2Register>>,
    TError,
    { data: BodyType<ResiterUserDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserV2Register>>,
  TError,
  { data: BodyType<ResiterUserDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserV2Register>>,
    { data: BodyType<ResiterUserDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserV2Register(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserV2RegisterMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserV2Register>>
>;
export type PostUserV2RegisterMutationBody = BodyType<ResiterUserDto>;
export type PostUserV2RegisterMutationError = ErrorType<unknown>;

export const usePostUserV2Register = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserV2Register>>,
    TError,
    { data: BodyType<ResiterUserDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserV2Register>>,
  TError,
  { data: BodyType<ResiterUserDto> },
  TContext
> => {
  const mutationOptions = getPostUserV2RegisterMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserV2Profile = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<UserV2Dto>({ url: '/user/V2/profile', method: 'GET', signal }, options);
};

export const getGetUserV2ProfileQueryKey = () => {
  return ['/user/V2/profile'] as const;
};

export const getGetUserV2ProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserV2Profile>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserV2Profile>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserV2ProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserV2Profile>>> = ({ signal }) =>
    getUserV2Profile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserV2Profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserV2ProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getUserV2Profile>>>;
export type GetUserV2ProfileQueryError = ErrorType<unknown>;

export const useGetUserV2Profile = <
  TData = Awaited<ReturnType<typeof getUserV2Profile>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserV2Profile>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserV2ProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserUserIp = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<UserIpDto>({ url: '/user/user-ip', method: 'GET', signal }, options);
};

export const getGetUserUserIpQueryKey = () => {
  return ['/user/user-ip'] as const;
};

export const getGetUserUserIpQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserUserIp>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserUserIp>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserUserIpQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserUserIp>>> = ({ signal }) =>
    getUserUserIp(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserUserIp>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserUserIpQueryResult = NonNullable<Awaited<ReturnType<typeof getUserUserIp>>>;
export type GetUserUserIpQueryError = ErrorType<unknown>;

export const useGetUserUserIp = <
  TData = Awaited<ReturnType<typeof getUserUserIp>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserUserIp>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserUserIpQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserV2AccessToken = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>({ url: '/user/V2/access-token', method: 'GET', signal }, options);
};

export const getGetUserV2AccessTokenQueryKey = () => {
  return ['/user/V2/access-token'] as const;
};

export const getGetUserV2AccessTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserV2AccessToken>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserV2AccessToken>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserV2AccessTokenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserV2AccessToken>>> = ({ signal }) =>
    getUserV2AccessToken(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserV2AccessToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserV2AccessTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserV2AccessToken>>
>;
export type GetUserV2AccessTokenQueryError = ErrorType<unknown>;

export const useGetUserV2AccessToken = <
  TData = Awaited<ReturnType<typeof getUserV2AccessToken>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserV2AccessToken>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserV2AccessTokenQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteUserV2Withdraw = (
  params?: DeleteUserV2WithdrawParams,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>({ url: '/user/V2/withdraw', method: 'DELETE', params }, options);
};

export const getDeleteUserV2WithdrawMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserV2Withdraw>>,
    TError,
    { params?: DeleteUserV2WithdrawParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserV2Withdraw>>,
  TError,
  { params?: DeleteUserV2WithdrawParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserV2Withdraw>>,
    { params?: DeleteUserV2WithdrawParams }
  > = (props) => {
    const { params } = props ?? {};

    return deleteUserV2Withdraw(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserV2WithdrawMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserV2Withdraw>>
>;

export type DeleteUserV2WithdrawMutationError = ErrorType<unknown>;

export const useDeleteUserV2Withdraw = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserV2Withdraw>>,
    TError,
    { params?: DeleteUserV2WithdrawParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserV2Withdraw>>,
  TError,
  { params?: DeleteUserV2WithdrawParams },
  TContext
> => {
  const mutationOptions = getDeleteUserV2WithdrawMutationOptions(options);

  return useMutation(mutationOptions);
};
export const patchUserV2RegionBjdCode = (
  bjdCode: string,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>({ url: `/user/V2/region/${bjdCode}`, method: 'PATCH' }, options);
};

export const getPatchUserV2RegionBjdCodeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserV2RegionBjdCode>>,
    TError,
    { bjdCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUserV2RegionBjdCode>>,
  TError,
  { bjdCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUserV2RegionBjdCode>>,
    { bjdCode: string }
  > = (props) => {
    const { bjdCode } = props ?? {};

    return patchUserV2RegionBjdCode(bjdCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchUserV2RegionBjdCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUserV2RegionBjdCode>>
>;

export type PatchUserV2RegionBjdCodeMutationError = ErrorType<unknown>;

export const usePatchUserV2RegionBjdCode = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserV2RegionBjdCode>>,
    TError,
    { bjdCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchUserV2RegionBjdCode>>,
  TError,
  { bjdCode: string },
  TContext
> => {
  const mutationOptions = getPatchUserV2RegionBjdCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postUserAppLoginComplete = (
  appLoginLogoutModel: BodyType<AppLoginLogoutModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/app-login/complete',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: appLoginLogoutModel,
    },
    options
  );
};

export const getPostUserAppLoginCompleteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAppLoginComplete>>,
    TError,
    { data: BodyType<AppLoginLogoutModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserAppLoginComplete>>,
  TError,
  { data: BodyType<AppLoginLogoutModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserAppLoginComplete>>,
    { data: BodyType<AppLoginLogoutModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserAppLoginComplete(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserAppLoginCompleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserAppLoginComplete>>
>;
export type PostUserAppLoginCompleteMutationBody = BodyType<AppLoginLogoutModel>;
export type PostUserAppLoginCompleteMutationError = ErrorType<unknown>;

export const usePostUserAppLoginComplete = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAppLoginComplete>>,
    TError,
    { data: BodyType<AppLoginLogoutModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserAppLoginComplete>>,
  TError,
  { data: BodyType<AppLoginLogoutModel> },
  TContext
> => {
  const mutationOptions = getPostUserAppLoginCompleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const deleteUserLogout = (
  params?: DeleteUserLogoutParams,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>({ url: '/user/logout', method: 'DELETE', params }, options);
};

export const getDeleteUserLogoutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserLogout>>,
    TError,
    { params?: DeleteUserLogoutParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserLogout>>,
  TError,
  { params?: DeleteUserLogoutParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserLogout>>,
    { params?: DeleteUserLogoutParams }
  > = (props) => {
    const { params } = props ?? {};

    return deleteUserLogout(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserLogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserLogout>>
>;

export type DeleteUserLogoutMutationError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const useDeleteUserLogout = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserLogout>>,
    TError,
    { params?: DeleteUserLogoutParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserLogout>>,
  TError,
  { params?: DeleteUserLogoutParams },
  TContext
> => {
  const mutationOptions = getDeleteUserLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 앱에서만 호출하는 API로 웹에서도 로그아웃 시 뭔가를 하려면 별도로 만들던가 해야한다.
 * @summary 앱 전용 로그아웃
 */
export const deleteUserLogoutV2 = (
  appLoginLogoutModel: BodyType<AppLoginLogoutModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/logout/v2',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: appLoginLogoutModel,
    },
    options
  );
};

export const getDeleteUserLogoutV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserLogoutV2>>,
    TError,
    { data: BodyType<AppLoginLogoutModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserLogoutV2>>,
  TError,
  { data: BodyType<AppLoginLogoutModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserLogoutV2>>,
    { data: BodyType<AppLoginLogoutModel> }
  > = (props) => {
    const { data } = props ?? {};

    return deleteUserLogoutV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserLogoutV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserLogoutV2>>
>;
export type DeleteUserLogoutV2MutationBody = BodyType<AppLoginLogoutModel>;
export type DeleteUserLogoutV2MutationError = ErrorType<unknown>;

/**
 * @summary 앱 전용 로그아웃
 */
export const useDeleteUserLogoutV2 = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserLogoutV2>>,
    TError,
    { data: BodyType<AppLoginLogoutModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserLogoutV2>>,
  TError,
  { data: BodyType<AppLoginLogoutModel> },
  TContext
> => {
  const mutationOptions = getDeleteUserLogoutV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const getUserNotification = (
  params?: GetUserNotificationParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: '/user/notification', method: 'GET', params, signal },
    options
  );
};

export const getGetUserNotificationQueryKey = (params?: GetUserNotificationParams) => {
  return ['/user/notification', ...(params ? [params] : [])] as const;
};

export const getGetUserNotificationQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserNotification>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNotificationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotification>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNotificationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNotification>>> = ({ signal }) =>
    getUserNotification(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserNotification>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserNotificationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserNotification>>
>;
export type GetUserNotificationQueryError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const useGetUserNotification = <
  TData = Awaited<ReturnType<typeof getUserNotification>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNotificationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotification>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserNotificationQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserNotificationV2 = (
  params?: GetUserNotificationV2Params,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: '/user/notification/v2', method: 'GET', params, signal },
    options
  );
};

export const getGetUserNotificationV2QueryKey = (params?: GetUserNotificationV2Params) => {
  return ['/user/notification/v2', ...(params ? [params] : [])] as const;
};

export const getGetUserNotificationV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserNotificationV2>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNotificationV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotificationV2>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNotificationV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNotificationV2>>> = ({ signal }) =>
    getUserNotificationV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserNotificationV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserNotificationV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserNotificationV2>>
>;
export type GetUserNotificationV2QueryError = ErrorType<unknown>;

export const useGetUserNotificationV2 = <
  TData = Awaited<ReturnType<typeof getUserNotificationV2>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNotificationV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotificationV2>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserNotificationV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const getUserNotificationId = (
  id: number,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/notification/${id}`, method: 'GET', signal },
    options
  );
};

export const getGetUserNotificationIdQueryKey = (id: number) => {
  return [`/user/notification/${id}`] as const;
};

export const getGetUserNotificationIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserNotificationId>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotificationId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNotificationIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNotificationId>>> = ({ signal }) =>
    getUserNotificationId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserNotificationId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserNotificationIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserNotificationId>>
>;
export type GetUserNotificationIdQueryError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const useGetUserNotificationId = <
  TData = Awaited<ReturnType<typeof getUserNotificationId>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotificationId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserNotificationIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserNotificationNew = (
  params?: GetUserNotificationNewParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: '/user/notification/new', method: 'GET', params, signal },
    options
  );
};

export const getGetUserNotificationNewQueryKey = (params?: GetUserNotificationNewParams) => {
  return ['/user/notification/new', ...(params ? [params] : [])] as const;
};

export const getGetUserNotificationNewQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserNotificationNew>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNotificationNewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotificationNew>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNotificationNewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNotificationNew>>> = ({ signal }) =>
    getUserNotificationNew(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserNotificationNew>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserNotificationNewQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserNotificationNew>>
>;
export type GetUserNotificationNewQueryError = ErrorType<unknown>;

export const useGetUserNotificationNew = <
  TData = Awaited<ReturnType<typeof getUserNotificationNew>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNotificationNewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotificationNew>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserNotificationNewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postUserConfigKey = (
  key: string,
  params?: PostUserConfigKeyParams,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>({ url: `/user/config/${key}`, method: 'POST', params }, options);
};

export const getPostUserConfigKeyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserConfigKey>>,
    TError,
    { key: string; params?: PostUserConfigKeyParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserConfigKey>>,
  TError,
  { key: string; params?: PostUserConfigKeyParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserConfigKey>>,
    { key: string; params?: PostUserConfigKeyParams }
  > = (props) => {
    const { key, params } = props ?? {};

    return postUserConfigKey(key, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserConfigKeyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserConfigKey>>
>;

export type PostUserConfigKeyMutationError = ErrorType<unknown>;

export const usePostUserConfigKey = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserConfigKey>>,
    TError,
    { key: string; params?: PostUserConfigKeyParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserConfigKey>>,
  TError,
  { key: string; params?: PostUserConfigKeyParams },
  TContext
> => {
  const mutationOptions = getPostUserConfigKeyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserConfigKey = (
  key: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>({ url: `/user/config/${key}`, method: 'GET', signal }, options);
};

export const getGetUserConfigKeyQueryKey = (key: string) => {
  return [`/user/config/${key}`] as const;
};

export const getGetUserConfigKeyQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserConfigKey>>,
  TError = ErrorType<unknown>
>(
  key: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserConfigKey>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserConfigKeyQueryKey(key);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserConfigKey>>> = ({ signal }) =>
    getUserConfigKey(key, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!key, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserConfigKey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserConfigKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getUserConfigKey>>>;
export type GetUserConfigKeyQueryError = ErrorType<unknown>;

export const useGetUserConfigKey = <
  TData = Awaited<ReturnType<typeof getUserConfigKey>>,
  TError = ErrorType<unknown>
>(
  key: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserConfigKey>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserConfigKeyQueryOptions(key, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const getUserFavoritePlace = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>({ url: '/user/favorite-place', method: 'GET', signal }, options);
};

export const getGetUserFavoritePlaceQueryKey = () => {
  return ['/user/favorite-place'] as const;
};

export const getGetUserFavoritePlaceQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFavoritePlace>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFavoritePlace>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFavoritePlaceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFavoritePlace>>> = ({ signal }) =>
    getUserFavoritePlace(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFavoritePlace>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFavoritePlaceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFavoritePlace>>
>;
export type GetUserFavoritePlaceQueryError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const useGetUserFavoritePlace = <
  TData = Awaited<ReturnType<typeof getUserFavoritePlace>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFavoritePlace>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFavoritePlaceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const postUserFavoritePlace = (
  favoritePlaceModel: BodyType<FavoritePlaceModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/favorite-place',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: favoritePlaceModel,
    },
    options
  );
};

export const getPostUserFavoritePlaceMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFavoritePlace>>,
    TError,
    { data: BodyType<FavoritePlaceModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserFavoritePlace>>,
  TError,
  { data: BodyType<FavoritePlaceModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserFavoritePlace>>,
    { data: BodyType<FavoritePlaceModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserFavoritePlace(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserFavoritePlaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserFavoritePlace>>
>;
export type PostUserFavoritePlaceMutationBody = BodyType<FavoritePlaceModel>;
export type PostUserFavoritePlaceMutationError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const usePostUserFavoritePlace = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFavoritePlace>>,
    TError,
    { data: BodyType<FavoritePlaceModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserFavoritePlace>>,
  TError,
  { data: BodyType<FavoritePlaceModel> },
  TContext
> => {
  const mutationOptions = getPostUserFavoritePlaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const deleteUserFavoritePlaceIndex = (
  index: number,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/favorite-place/${index}`, method: 'DELETE' },
    options
  );
};

export const getDeleteUserFavoritePlaceIndexMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFavoritePlaceIndex>>,
    TError,
    { index: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserFavoritePlaceIndex>>,
  TError,
  { index: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserFavoritePlaceIndex>>,
    { index: number }
  > = (props) => {
    const { index } = props ?? {};

    return deleteUserFavoritePlaceIndex(index, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserFavoritePlaceIndexMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserFavoritePlaceIndex>>
>;

export type DeleteUserFavoritePlaceIndexMutationError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const useDeleteUserFavoritePlaceIndex = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFavoritePlaceIndex>>,
    TError,
    { index: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserFavoritePlaceIndex>>,
  TError,
  { index: number },
  TContext
> => {
  const mutationOptions = getDeleteUserFavoritePlaceIndexMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const postUserFavoritePlaceLog = (
  userPlaceLogModel: BodyType<UserPlaceLogModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/favorite-place-log',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: userPlaceLogModel,
    },
    options
  );
};

export const getPostUserFavoritePlaceLogMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFavoritePlaceLog>>,
    TError,
    { data: BodyType<UserPlaceLogModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserFavoritePlaceLog>>,
  TError,
  { data: BodyType<UserPlaceLogModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserFavoritePlaceLog>>,
    { data: BodyType<UserPlaceLogModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserFavoritePlaceLog(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserFavoritePlaceLogMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserFavoritePlaceLog>>
>;
export type PostUserFavoritePlaceLogMutationBody = BodyType<UserPlaceLogModel>;
export type PostUserFavoritePlaceLogMutationError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const usePostUserFavoritePlaceLog = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFavoritePlaceLog>>,
    TError,
    { data: BodyType<UserPlaceLogModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserFavoritePlaceLog>>,
  TError,
  { data: BodyType<UserPlaceLogModel> },
  TContext
> => {
  const mutationOptions = getPostUserFavoritePlaceLogMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const getUserNotificationR2 = (
  params?: GetUserNotificationR2Params,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: '/user/notification/r2', method: 'GET', params, signal },
    options
  );
};

export const getGetUserNotificationR2QueryKey = (params?: GetUserNotificationR2Params) => {
  return ['/user/notification/r2', ...(params ? [params] : [])] as const;
};

export const getGetUserNotificationR2QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserNotificationR2>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNotificationR2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotificationR2>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNotificationR2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNotificationR2>>> = ({ signal }) =>
    getUserNotificationR2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserNotificationR2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserNotificationR2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserNotificationR2>>
>;
export type GetUserNotificationR2QueryError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const useGetUserNotificationR2 = <
  TData = Awaited<ReturnType<typeof getUserNotificationR2>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNotificationR2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNotificationR2>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserNotificationR2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 단지 방문 로그
 */
export const postUserDanjiVisit = (
  danjiVisitModel: BodyType<DanjiVisitModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/danji/visit',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: danjiVisitModel,
    },
    options
  );
};

export const getPostUserDanjiVisitMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserDanjiVisit>>,
    TError,
    { data: BodyType<DanjiVisitModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserDanjiVisit>>,
  TError,
  { data: BodyType<DanjiVisitModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserDanjiVisit>>,
    { data: BodyType<DanjiVisitModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserDanjiVisit(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserDanjiVisitMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserDanjiVisit>>
>;
export type PostUserDanjiVisitMutationBody = BodyType<DanjiVisitModel>;
export type PostUserDanjiVisitMutationError = ErrorType<unknown>;

/**
 * @summary 단지 방문 로그
 */
export const usePostUserDanjiVisit = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserDanjiVisit>>,
    TError,
    { data: BodyType<DanjiVisitModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserDanjiVisit>>,
  TError,
  { data: BodyType<DanjiVisitModel> },
  TContext
> => {
  const mutationOptions = getPostUserDanjiVisitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 레퍼럴 정보
 */
export const getUserReferralCategory = (
  category: ReferralCategoryType,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/referral/${category}`, method: 'GET', signal },
    options
  );
};

export const getGetUserReferralCategoryQueryKey = (category: ReferralCategoryType) => {
  return [`/user/referral/${category}`] as const;
};

export const getGetUserReferralCategoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserReferralCategory>>,
  TError = ErrorType<unknown>
>(
  category: ReferralCategoryType,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserReferralCategory>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserReferralCategoryQueryKey(category);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserReferralCategory>>> = ({ signal }) =>
    getUserReferralCategory(category, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!category, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserReferralCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserReferralCategoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserReferralCategory>>
>;
export type GetUserReferralCategoryQueryError = ErrorType<unknown>;

/**
 * @summary 레퍼럴 정보
 */
export const useGetUserReferralCategory = <
  TData = Awaited<ReturnType<typeof getUserReferralCategory>>,
  TError = ErrorType<unknown>
>(
  category: ReferralCategoryType,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserReferralCategory>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserReferralCategoryQueryOptions(category, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 레퍼럴 정보 업데이트
 */
export const putUserReferralCategoryReferralId = (
  category: ReferralCategoryType,
  referralId: string,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/referral/${category}/${referralId}`, method: 'PUT' },
    options
  );
};

export const getPutUserReferralCategoryReferralIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserReferralCategoryReferralId>>,
    TError,
    { category: ReferralCategoryType; referralId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserReferralCategoryReferralId>>,
  TError,
  { category: ReferralCategoryType; referralId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserReferralCategoryReferralId>>,
    { category: ReferralCategoryType; referralId: string }
  > = (props) => {
    const { category, referralId } = props ?? {};

    return putUserReferralCategoryReferralId(category, referralId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserReferralCategoryReferralIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserReferralCategoryReferralId>>
>;

export type PutUserReferralCategoryReferralIdMutationError = ErrorType<unknown>;

/**
 * @summary 레퍼럴 정보 업데이트
 */
export const usePutUserReferralCategoryReferralId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserReferralCategoryReferralId>>,
    TError,
    { category: ReferralCategoryType; referralId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserReferralCategoryReferralId>>,
  TError,
  { category: ReferralCategoryType; referralId: string },
  TContext
> => {
  const mutationOptions = getPutUserReferralCategoryReferralIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 부동산 소유 확인
 */
export const postUserVerifyRealtyOwner = (
  verifyRealtyOwnerModel: BodyType<VerifyRealtyOwnerModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/verify-realty-owner',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: verifyRealtyOwnerModel,
    },
    options
  );
};

export const getPostUserVerifyRealtyOwnerMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserVerifyRealtyOwner>>,
    TError,
    { data: BodyType<VerifyRealtyOwnerModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserVerifyRealtyOwner>>,
  TError,
  { data: BodyType<VerifyRealtyOwnerModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserVerifyRealtyOwner>>,
    { data: BodyType<VerifyRealtyOwnerModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserVerifyRealtyOwner(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserVerifyRealtyOwnerMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserVerifyRealtyOwner>>
>;
export type PostUserVerifyRealtyOwnerMutationBody = BodyType<VerifyRealtyOwnerModel>;
export type PostUserVerifyRealtyOwnerMutationError = ErrorType<unknown>;

/**
 * @summary 부동산 소유 확인
 */
export const usePostUserVerifyRealtyOwner = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserVerifyRealtyOwner>>,
    TError,
    { data: BodyType<VerifyRealtyOwnerModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserVerifyRealtyOwner>>,
  TError,
  { data: BodyType<VerifyRealtyOwnerModel> },
  TContext
> => {
  const mutationOptions = getPostUserVerifyRealtyOwnerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary UID를 AES암호화
 */
export const getUserGoodkingEncryptUid = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<EncryptUserIdDto>(
    { url: '/user/goodking-encrypt-uid', method: 'GET', signal },
    options
  );
};

export const getGetUserGoodkingEncryptUidQueryKey = () => {
  return ['/user/goodking-encrypt-uid'] as const;
};

export const getGetUserGoodkingEncryptUidQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserGoodkingEncryptUid>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserGoodkingEncryptUid>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserGoodkingEncryptUidQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGoodkingEncryptUid>>> = ({
    signal,
  }) => getUserGoodkingEncryptUid(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserGoodkingEncryptUid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserGoodkingEncryptUidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserGoodkingEncryptUid>>
>;
export type GetUserGoodkingEncryptUidQueryError = ErrorType<unknown>;

/**
 * @summary UID를 AES암호화
 */
export const useGetUserGoodkingEncryptUid = <
  TData = Awaited<ReturnType<typeof getUserGoodkingEncryptUid>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserGoodkingEncryptUid>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserGoodkingEncryptUidQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 경매 필터 추가
 */
export const putUserAuctionFilter = (
  createAuctionFilterQueryModel: BodyType<CreateAuctionFilterQueryModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/auction/filter',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: createAuctionFilterQueryModel,
    },
    options
  );
};

export const getPutUserAuctionFilterMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserAuctionFilter>>,
    TError,
    { data: BodyType<CreateAuctionFilterQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserAuctionFilter>>,
  TError,
  { data: BodyType<CreateAuctionFilterQueryModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserAuctionFilter>>,
    { data: BodyType<CreateAuctionFilterQueryModel> }
  > = (props) => {
    const { data } = props ?? {};

    return putUserAuctionFilter(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserAuctionFilterMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserAuctionFilter>>
>;
export type PutUserAuctionFilterMutationBody = BodyType<CreateAuctionFilterQueryModel>;
export type PutUserAuctionFilterMutationError = ErrorType<unknown>;

/**
 * @summary 경매 필터 추가
 */
export const usePutUserAuctionFilter = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserAuctionFilter>>,
    TError,
    { data: BodyType<CreateAuctionFilterQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserAuctionFilter>>,
  TError,
  { data: BodyType<CreateAuctionFilterQueryModel> },
  TContext
> => {
  const mutationOptions = getPutUserAuctionFilterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 필터 일괄 조회
 */
export const getUserAuctionFilter = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<AuctionFilterAllDto>(
    { url: '/user/auction/filter', method: 'GET', signal },
    options
  );
};

export const getGetUserAuctionFilterQueryKey = () => {
  return ['/user/auction/filter'] as const;
};

export const getGetUserAuctionFilterQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAuctionFilter>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserAuctionFilter>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAuctionFilterQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAuctionFilter>>> = ({ signal }) =>
    getUserAuctionFilter(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAuctionFilter>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserAuctionFilterQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAuctionFilter>>
>;
export type GetUserAuctionFilterQueryError = ErrorType<unknown>;

/**
 * @summary 경매 필터 일괄 조회
 */
export const useGetUserAuctionFilter = <
  TData = Awaited<ReturnType<typeof getUserAuctionFilter>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserAuctionFilter>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserAuctionFilterQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 경매 필터 업데이트
 */
export const postUserAuctionFilter = (
  updateAuctionFilterQueryModel: BodyType<UpdateAuctionFilterQueryModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<boolean>(
    {
      url: '/user/auction/filter',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateAuctionFilterQueryModel,
    },
    options
  );
};

export const getPostUserAuctionFilterMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAuctionFilter>>,
    TError,
    { data: BodyType<UpdateAuctionFilterQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserAuctionFilter>>,
  TError,
  { data: BodyType<UpdateAuctionFilterQueryModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserAuctionFilter>>,
    { data: BodyType<UpdateAuctionFilterQueryModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserAuctionFilter(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserAuctionFilterMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserAuctionFilter>>
>;
export type PostUserAuctionFilterMutationBody = BodyType<UpdateAuctionFilterQueryModel>;
export type PostUserAuctionFilterMutationError = ErrorType<unknown>;

/**
 * @summary 경매 필터 업데이트
 */
export const usePostUserAuctionFilter = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAuctionFilter>>,
    TError,
    { data: BodyType<UpdateAuctionFilterQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserAuctionFilter>>,
  TError,
  { data: BodyType<UpdateAuctionFilterQueryModel> },
  TContext
> => {
  const mutationOptions = getPostUserAuctionFilterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 필터 일괄 삭제
 */
export const deleteUserAuctionFilter = (options?: SecondParameter<typeof customFetch>) => {
  return customFetch<ApiResponse>({ url: '/user/auction/filter', method: 'DELETE' }, options);
};

export const getDeleteUserAuctionFilterMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFilter>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserAuctionFilter>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserAuctionFilter>>,
    void
  > = () => {
    return deleteUserAuctionFilter(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserAuctionFilterMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserAuctionFilter>>
>;

export type DeleteUserAuctionFilterMutationError = ErrorType<unknown>;

/**
 * @summary 경매 필터 일괄 삭제
 */
export const useDeleteUserAuctionFilter = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFilter>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserAuctionFilter>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeleteUserAuctionFilterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 필터 조회
 */
export const getUserAuctionFilterId = (
  id: number,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<AuctionFilterDto>(
    { url: `/user/auction/filter/${id}`, method: 'GET', signal },
    options
  );
};

export const getGetUserAuctionFilterIdQueryKey = (id: number) => {
  return [`/user/auction/filter/${id}`] as const;
};

export const getGetUserAuctionFilterIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAuctionFilterId>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserAuctionFilterId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAuctionFilterIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAuctionFilterId>>> = ({ signal }) =>
    getUserAuctionFilterId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAuctionFilterId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserAuctionFilterIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAuctionFilterId>>
>;
export type GetUserAuctionFilterIdQueryError = ErrorType<unknown>;

/**
 * @summary 경매 필터 조회
 */
export const useGetUserAuctionFilterId = <
  TData = Awaited<ReturnType<typeof getUserAuctionFilterId>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserAuctionFilterId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserAuctionFilterIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 경매 필터 삭제
 */
export const deleteUserAuctionFilterId = (
  id: number,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<boolean>({ url: `/user/auction/filter/${id}`, method: 'DELETE' }, options);
};

export const getDeleteUserAuctionFilterIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFilterId>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserAuctionFilterId>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserAuctionFilterId>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUserAuctionFilterId(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserAuctionFilterIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserAuctionFilterId>>
>;

export type DeleteUserAuctionFilterIdMutationError = ErrorType<unknown>;

/**
 * @summary 경매 필터 삭제
 */
export const useDeleteUserAuctionFilterId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFilterId>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserAuctionFilterId>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteUserAuctionFilterIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 즐겨찾기 폴더 추가
 */
export const putUserAuctionFavoriteFolder = (
  createAuctionFavoriteFolderQueryModel: BodyType<CreateAuctionFavoriteFolderQueryModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<AuctionFavoriteFolderResultCode>(
    {
      url: '/user/auction/favorite/folder',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: createAuctionFavoriteFolderQueryModel,
    },
    options
  );
};

export const getPutUserAuctionFavoriteFolderMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserAuctionFavoriteFolder>>,
    TError,
    { data: BodyType<CreateAuctionFavoriteFolderQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserAuctionFavoriteFolder>>,
  TError,
  { data: BodyType<CreateAuctionFavoriteFolderQueryModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserAuctionFavoriteFolder>>,
    { data: BodyType<CreateAuctionFavoriteFolderQueryModel> }
  > = (props) => {
    const { data } = props ?? {};

    return putUserAuctionFavoriteFolder(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserAuctionFavoriteFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserAuctionFavoriteFolder>>
>;
export type PutUserAuctionFavoriteFolderMutationBody =
  BodyType<CreateAuctionFavoriteFolderQueryModel>;
export type PutUserAuctionFavoriteFolderMutationError = ErrorType<unknown>;

/**
 * @summary 경매 즐겨찾기 폴더 추가
 */
export const usePutUserAuctionFavoriteFolder = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserAuctionFavoriteFolder>>,
    TError,
    { data: BodyType<CreateAuctionFavoriteFolderQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserAuctionFavoriteFolder>>,
  TError,
  { data: BodyType<CreateAuctionFavoriteFolderQueryModel> },
  TContext
> => {
  const mutationOptions = getPutUserAuctionFavoriteFolderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 즐겨찾기 폴더 이름 변경
 */
export const postUserAuctionFavoriteFolder = (
  updateAuctionFavoriteFolderQueryModel: BodyType<UpdateAuctionFavoriteFolderQueryModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<AuctionFavoriteFolderResultCode>(
    {
      url: '/user/auction/favorite/folder',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateAuctionFavoriteFolderQueryModel,
    },
    options
  );
};

export const getPostUserAuctionFavoriteFolderMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAuctionFavoriteFolder>>,
    TError,
    { data: BodyType<UpdateAuctionFavoriteFolderQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserAuctionFavoriteFolder>>,
  TError,
  { data: BodyType<UpdateAuctionFavoriteFolderQueryModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserAuctionFavoriteFolder>>,
    { data: BodyType<UpdateAuctionFavoriteFolderQueryModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserAuctionFavoriteFolder(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserAuctionFavoriteFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserAuctionFavoriteFolder>>
>;
export type PostUserAuctionFavoriteFolderMutationBody =
  BodyType<UpdateAuctionFavoriteFolderQueryModel>;
export type PostUserAuctionFavoriteFolderMutationError = ErrorType<unknown>;

/**
 * @summary 경매 즐겨찾기 폴더 이름 변경
 */
export const usePostUserAuctionFavoriteFolder = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAuctionFavoriteFolder>>,
    TError,
    { data: BodyType<UpdateAuctionFavoriteFolderQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserAuctionFavoriteFolder>>,
  TError,
  { data: BodyType<UpdateAuctionFavoriteFolderQueryModel> },
  TContext
> => {
  const mutationOptions = getPostUserAuctionFavoriteFolderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 즐겨찾기 폴더 리스트 조회 - 폴더 정보만
 */
export const getUserAuctionFavoriteFolder = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<AuctionFilterAllDto>(
    { url: '/user/auction/favorite/folder', method: 'GET', signal },
    options
  );
};

export const getGetUserAuctionFavoriteFolderQueryKey = () => {
  return ['/user/auction/favorite/folder'] as const;
};

export const getGetUserAuctionFavoriteFolderQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAuctionFavoriteFolder>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserAuctionFavoriteFolder>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAuctionFavoriteFolderQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAuctionFavoriteFolder>>> = ({
    signal,
  }) => getUserAuctionFavoriteFolder(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAuctionFavoriteFolder>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserAuctionFavoriteFolderQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAuctionFavoriteFolder>>
>;
export type GetUserAuctionFavoriteFolderQueryError = ErrorType<unknown>;

/**
 * @summary 경매 즐겨찾기 폴더 리스트 조회 - 폴더 정보만
 */
export const useGetUserAuctionFavoriteFolder = <
  TData = Awaited<ReturnType<typeof getUserAuctionFavoriteFolder>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserAuctionFavoriteFolder>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserAuctionFavoriteFolderQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 겅매 즐겨찾기 폴더 일괄 삭제
 */
export const deleteUserAuctionFavoriteFolder = (options?: SecondParameter<typeof customFetch>) => {
  return customFetch<ApiResponse>(
    { url: '/user/auction/favorite/folder', method: 'DELETE' },
    options
  );
};

export const getDeleteUserAuctionFavoriteFolderMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolder>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolder>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolder>>,
    void
  > = () => {
    return deleteUserAuctionFavoriteFolder(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserAuctionFavoriteFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolder>>
>;

export type DeleteUserAuctionFavoriteFolderMutationError = ErrorType<unknown>;

/**
 * @summary 겅매 즐겨찾기 폴더 일괄 삭제
 */
export const useDeleteUserAuctionFavoriteFolder = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolder>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolder>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeleteUserAuctionFavoriteFolderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 즐겨찾기 폴더 조회 - 경매 아이템 포함
 */
export const getUserAuctionFavoriteFolderId = (
  id: number,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/auction/favorite/folder/${id}`, method: 'GET', signal },
    options
  );
};

export const getGetUserAuctionFavoriteFolderIdQueryKey = (id: number) => {
  return [`/user/auction/favorite/folder/${id}`] as const;
};

export const getGetUserAuctionFavoriteFolderIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAuctionFavoriteFolderId>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserAuctionFavoriteFolderId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAuctionFavoriteFolderIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAuctionFavoriteFolderId>>> = ({
    signal,
  }) => getUserAuctionFavoriteFolderId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAuctionFavoriteFolderId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserAuctionFavoriteFolderIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAuctionFavoriteFolderId>>
>;
export type GetUserAuctionFavoriteFolderIdQueryError = ErrorType<unknown>;

/**
 * @summary 경매 즐겨찾기 폴더 조회 - 경매 아이템 포함
 */
export const useGetUserAuctionFavoriteFolderId = <
  TData = Awaited<ReturnType<typeof getUserAuctionFavoriteFolderId>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserAuctionFavoriteFolderId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserAuctionFavoriteFolderIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 경매 즐겨찾기 폴더 삭제
 */
export const deleteUserAuctionFavoriteFolderId = (
  id: number,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<boolean>(
    { url: `/user/auction/favorite/folder/${id}`, method: 'DELETE' },
    options
  );
};

export const getDeleteUserAuctionFavoriteFolderIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderId>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderId>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderId>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUserAuctionFavoriteFolderId(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserAuctionFavoriteFolderIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderId>>
>;

export type DeleteUserAuctionFavoriteFolderIdMutationError = ErrorType<unknown>;

/**
 * @summary 경매 즐겨찾기 폴더 삭제
 */
export const useDeleteUserAuctionFavoriteFolderId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderId>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderId>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteUserAuctionFavoriteFolderIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 폴더에 아이템 추가하기 - 멀티 폴더에 멀티 아이템이 들어감
 */
export const putUserAuctionFavoriteFolderItem = (
  addAuctionFavoriteFolderItemQueryModel: BodyType<AddAuctionFavoriteFolderItemQueryModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/auction/favorite/folder/item',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: addAuctionFavoriteFolderItemQueryModel,
    },
    options
  );
};

export const getPutUserAuctionFavoriteFolderItemMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserAuctionFavoriteFolderItem>>,
    TError,
    { data: BodyType<AddAuctionFavoriteFolderItemQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserAuctionFavoriteFolderItem>>,
  TError,
  { data: BodyType<AddAuctionFavoriteFolderItemQueryModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserAuctionFavoriteFolderItem>>,
    { data: BodyType<AddAuctionFavoriteFolderItemQueryModel> }
  > = (props) => {
    const { data } = props ?? {};

    return putUserAuctionFavoriteFolderItem(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserAuctionFavoriteFolderItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserAuctionFavoriteFolderItem>>
>;
export type PutUserAuctionFavoriteFolderItemMutationBody =
  BodyType<AddAuctionFavoriteFolderItemQueryModel>;
export type PutUserAuctionFavoriteFolderItemMutationError = ErrorType<unknown>;

/**
 * @summary 폴더에 아이템 추가하기 - 멀티 폴더에 멀티 아이템이 들어감
 */
export const usePutUserAuctionFavoriteFolderItem = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserAuctionFavoriteFolderItem>>,
    TError,
    { data: BodyType<AddAuctionFavoriteFolderItemQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserAuctionFavoriteFolderItem>>,
  TError,
  { data: BodyType<AddAuctionFavoriteFolderItemQueryModel> },
  TContext
> => {
  const mutationOptions = getPutUserAuctionFavoriteFolderItemMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 폴더에 아이템 삭제하기 - 싱글 폴더에 멀티 아이템을 삭제함
 */
export const deleteUserAuctionFavoriteFolderItem = (
  removeAuctionFavoriteFolderItemQueryModel: BodyType<RemoveAuctionFavoriteFolderItemQueryModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/auction/favorite/folder/item',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: removeAuctionFavoriteFolderItemQueryModel,
    },
    options
  );
};

export const getDeleteUserAuctionFavoriteFolderItemMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderItem>>,
    TError,
    { data: BodyType<RemoveAuctionFavoriteFolderItemQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderItem>>,
  TError,
  { data: BodyType<RemoveAuctionFavoriteFolderItemQueryModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderItem>>,
    { data: BodyType<RemoveAuctionFavoriteFolderItemQueryModel> }
  > = (props) => {
    const { data } = props ?? {};

    return deleteUserAuctionFavoriteFolderItem(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserAuctionFavoriteFolderItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderItem>>
>;
export type DeleteUserAuctionFavoriteFolderItemMutationBody =
  BodyType<RemoveAuctionFavoriteFolderItemQueryModel>;
export type DeleteUserAuctionFavoriteFolderItemMutationError = ErrorType<unknown>;

/**
 * @summary 폴더에 아이템 삭제하기 - 싱글 폴더에 멀티 아이템을 삭제함
 */
export const useDeleteUserAuctionFavoriteFolderItem = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderItem>>,
    TError,
    { data: BodyType<RemoveAuctionFavoriteFolderItemQueryModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserAuctionFavoriteFolderItem>>,
  TError,
  { data: BodyType<RemoveAuctionFavoriteFolderItemQueryModel> },
  TContext
> => {
  const mutationOptions = getDeleteUserAuctionFavoriteFolderItemMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserDeviceDeviceId = (
  deviceId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/device/${deviceId}`, method: 'GET', signal },
    options
  );
};

export const getGetUserDeviceDeviceIdQueryKey = (deviceId: string) => {
  return [`/user/device/${deviceId}`] as const;
};

export const getGetUserDeviceDeviceIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserDeviceDeviceId>>,
  TError = ErrorType<unknown>
>(
  deviceId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceDeviceId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDeviceDeviceIdQueryKey(deviceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeviceDeviceId>>> = ({ signal }) =>
    getUserDeviceDeviceId(deviceId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!deviceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserDeviceDeviceId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserDeviceDeviceIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserDeviceDeviceId>>
>;
export type GetUserDeviceDeviceIdQueryError = ErrorType<unknown>;

export const useGetUserDeviceDeviceId = <
  TData = Awaited<ReturnType<typeof getUserDeviceDeviceId>>,
  TError = ErrorType<unknown>
>(
  deviceId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceDeviceId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserDeviceDeviceIdQueryOptions(deviceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putUserDeviceDeviceId = (
  deviceId: string,
  params?: PutUserDeviceDeviceIdParams,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/device/${deviceId}`, method: 'PUT', params },
    options
  );
};

export const getPutUserDeviceDeviceIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserDeviceDeviceId>>,
    TError,
    { deviceId: string; params?: PutUserDeviceDeviceIdParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserDeviceDeviceId>>,
  TError,
  { deviceId: string; params?: PutUserDeviceDeviceIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserDeviceDeviceId>>,
    { deviceId: string; params?: PutUserDeviceDeviceIdParams }
  > = (props) => {
    const { deviceId, params } = props ?? {};

    return putUserDeviceDeviceId(deviceId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserDeviceDeviceIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserDeviceDeviceId>>
>;

export type PutUserDeviceDeviceIdMutationError = ErrorType<unknown>;

export const usePutUserDeviceDeviceId = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserDeviceDeviceId>>,
    TError,
    { deviceId: string; params?: PutUserDeviceDeviceIdParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserDeviceDeviceId>>,
  TError,
  { deviceId: string; params?: PutUserDeviceDeviceIdParams },
  TContext
> => {
  const mutationOptions = getPutUserDeviceDeviceIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserDeviceNotificationId = (
  id: number,
  params?: GetUserDeviceNotificationIdParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/device-notification/${id}`, method: 'GET', params, signal },
    options
  );
};

export const getGetUserDeviceNotificationIdQueryKey = (
  id: number,
  params?: GetUserDeviceNotificationIdParams
) => {
  return [`/user/device-notification/${id}`, ...(params ? [params] : [])] as const;
};

export const getGetUserDeviceNotificationIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserDeviceNotificationId>>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: GetUserDeviceNotificationIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceNotificationId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDeviceNotificationIdQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeviceNotificationId>>> = ({
    signal,
  }) => getUserDeviceNotificationId(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserDeviceNotificationId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserDeviceNotificationIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserDeviceNotificationId>>
>;
export type GetUserDeviceNotificationIdQueryError = ErrorType<unknown>;

export const useGetUserDeviceNotificationId = <
  TData = Awaited<ReturnType<typeof getUserDeviceNotificationId>>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: GetUserDeviceNotificationIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceNotificationId>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserDeviceNotificationIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserDeviceNotificationNew = (
  params?: GetUserDeviceNotificationNewParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: '/user/device-notification/new', method: 'GET', params, signal },
    options
  );
};

export const getGetUserDeviceNotificationNewQueryKey = (
  params?: GetUserDeviceNotificationNewParams
) => {
  return ['/user/device-notification/new', ...(params ? [params] : [])] as const;
};

export const getGetUserDeviceNotificationNewQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserDeviceNotificationNew>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserDeviceNotificationNewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceNotificationNew>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDeviceNotificationNewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeviceNotificationNew>>> = ({
    signal,
  }) => getUserDeviceNotificationNew(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserDeviceNotificationNew>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserDeviceNotificationNewQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserDeviceNotificationNew>>
>;
export type GetUserDeviceNotificationNewQueryError = ErrorType<unknown>;

export const useGetUserDeviceNotificationNew = <
  TData = Awaited<ReturnType<typeof getUserDeviceNotificationNew>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserDeviceNotificationNewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceNotificationNew>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserDeviceNotificationNewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postUserDeviceConfigKey = (
  key: string,
  params?: PostUserDeviceConfigKeyParams,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/device-config/${key}`, method: 'POST', params },
    options
  );
};

export const getPostUserDeviceConfigKeyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserDeviceConfigKey>>,
    TError,
    { key: string; params?: PostUserDeviceConfigKeyParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserDeviceConfigKey>>,
  TError,
  { key: string; params?: PostUserDeviceConfigKeyParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserDeviceConfigKey>>,
    { key: string; params?: PostUserDeviceConfigKeyParams }
  > = (props) => {
    const { key, params } = props ?? {};

    return postUserDeviceConfigKey(key, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserDeviceConfigKeyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserDeviceConfigKey>>
>;

export type PostUserDeviceConfigKeyMutationError = ErrorType<unknown>;

export const usePostUserDeviceConfigKey = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserDeviceConfigKey>>,
    TError,
    { key: string; params?: PostUserDeviceConfigKeyParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserDeviceConfigKey>>,
  TError,
  { key: string; params?: PostUserDeviceConfigKeyParams },
  TContext
> => {
  const mutationOptions = getPostUserDeviceConfigKeyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserDeviceConfigKey = (
  key: string,
  params?: GetUserDeviceConfigKeyParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/device-config/${key}`, method: 'GET', params, signal },
    options
  );
};

export const getGetUserDeviceConfigKeyQueryKey = (
  key: string,
  params?: GetUserDeviceConfigKeyParams
) => {
  return [`/user/device-config/${key}`, ...(params ? [params] : [])] as const;
};

export const getGetUserDeviceConfigKeyQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserDeviceConfigKey>>,
  TError = ErrorType<unknown>
>(
  key: string,
  params?: GetUserDeviceConfigKeyParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceConfigKey>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDeviceConfigKeyQueryKey(key, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeviceConfigKey>>> = ({ signal }) =>
    getUserDeviceConfigKey(key, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!key, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserDeviceConfigKey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserDeviceConfigKeyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserDeviceConfigKey>>
>;
export type GetUserDeviceConfigKeyQueryError = ErrorType<unknown>;

export const useGetUserDeviceConfigKey = <
  TData = Awaited<ReturnType<typeof getUserDeviceConfigKey>>,
  TError = ErrorType<unknown>
>(
  key: string,
  params?: GetUserDeviceConfigKeyParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceConfigKey>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserDeviceConfigKeyQueryOptions(key, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 비회원 마케팅 동의 여부 마지막 업데이트 날짜 정보
 */
export const getUserDeviceTermsMarketing = (
  params?: GetUserDeviceTermsMarketingParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: '/user/device/terms/marketing', method: 'GET', params, signal },
    options
  );
};

export const getGetUserDeviceTermsMarketingQueryKey = (
  params?: GetUserDeviceTermsMarketingParams
) => {
  return ['/user/device/terms/marketing', ...(params ? [params] : [])] as const;
};

export const getGetUserDeviceTermsMarketingQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserDeviceTermsMarketing>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserDeviceTermsMarketingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceTermsMarketing>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDeviceTermsMarketingQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeviceTermsMarketing>>> = ({
    signal,
  }) => getUserDeviceTermsMarketing(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserDeviceTermsMarketing>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserDeviceTermsMarketingQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserDeviceTermsMarketing>>
>;
export type GetUserDeviceTermsMarketingQueryError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary 비회원 마케팅 동의 여부 마지막 업데이트 날짜 정보
 */
export const useGetUserDeviceTermsMarketing = <
  TData = Awaited<ReturnType<typeof getUserDeviceTermsMarketing>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserDeviceTermsMarketingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDeviceTermsMarketing>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserDeviceTermsMarketingQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 비회원 마케팅 동의 업데이트
 */
export const postUserDeviceTermsTernNameAgreeYn = (
  ternName: string,
  agreeYn: boolean,
  params?: PostUserDeviceTermsTernNameAgreeYnParams,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/device/terms/${ternName}/${agreeYn}`, method: 'POST', params },
    options
  );
};

export const getPostUserDeviceTermsTernNameAgreeYnMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserDeviceTermsTernNameAgreeYn>>,
    TError,
    { ternName: string; agreeYn: boolean; params?: PostUserDeviceTermsTernNameAgreeYnParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserDeviceTermsTernNameAgreeYn>>,
  TError,
  { ternName: string; agreeYn: boolean; params?: PostUserDeviceTermsTernNameAgreeYnParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserDeviceTermsTernNameAgreeYn>>,
    { ternName: string; agreeYn: boolean; params?: PostUserDeviceTermsTernNameAgreeYnParams }
  > = (props) => {
    const { ternName, agreeYn, params } = props ?? {};

    return postUserDeviceTermsTernNameAgreeYn(ternName, agreeYn, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserDeviceTermsTernNameAgreeYnMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserDeviceTermsTernNameAgreeYn>>
>;

export type PostUserDeviceTermsTernNameAgreeYnMutationError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary 비회원 마케팅 동의 업데이트
 */
export const usePostUserDeviceTermsTernNameAgreeYn = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserDeviceTermsTernNameAgreeYn>>,
    TError,
    { ternName: string; agreeYn: boolean; params?: PostUserDeviceTermsTernNameAgreeYnParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserDeviceTermsTernNameAgreeYn>>,
  TError,
  { ternName: string; agreeYn: boolean; params?: PostUserDeviceTermsTernNameAgreeYnParams },
  TContext
> => {
  const mutationOptions = getPostUserDeviceTermsTernNameAgreeYnMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 * @summary v2 단지 즐겨 찾기 등록
 */
export const postUserAlarmDanji = (
  alarmDanjiInputDto: BodyType<AlarmDanjiInputDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/alarm-danji',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: alarmDanjiInputDto,
    },
    options
  );
};

export const getPostUserAlarmDanjiMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAlarmDanji>>,
    TError,
    { data: BodyType<AlarmDanjiInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserAlarmDanji>>,
  TError,
  { data: BodyType<AlarmDanjiInputDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserAlarmDanji>>,
    { data: BodyType<AlarmDanjiInputDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserAlarmDanji(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserAlarmDanjiMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserAlarmDanji>>
>;
export type PostUserAlarmDanjiMutationBody = BodyType<AlarmDanjiInputDto>;
export type PostUserAlarmDanjiMutationError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary v2 단지 즐겨 찾기 등록
 */
export const usePostUserAlarmDanji = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAlarmDanji>>,
    TError,
    { data: BodyType<AlarmDanjiInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserAlarmDanji>>,
  TError,
  { data: BodyType<AlarmDanjiInputDto> },
  TContext
> => {
  const mutationOptions = getPostUserAlarmDanjiMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 * @summary v2 청약(+기타) 즐겨 찾기 등록
 */
export const postUserAlarm = (
  alarmInputDto: BodyType<AlarmInputDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/alarm',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: alarmInputDto,
    },
    options
  );
};

export const getPostUserAlarmMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAlarm>>,
    TError,
    { data: BodyType<AlarmInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserAlarm>>,
  TError,
  { data: BodyType<AlarmInputDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserAlarm>>,
    { data: BodyType<AlarmInputDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserAlarm(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserAlarmMutationResult = NonNullable<Awaited<ReturnType<typeof postUserAlarm>>>;
export type PostUserAlarmMutationBody = BodyType<AlarmInputDto>;
export type PostUserAlarmMutationError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary v2 청약(+기타) 즐겨 찾기 등록
 */
export const usePostUserAlarm = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserAlarm>>,
    TError,
    { data: BodyType<AlarmInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserAlarm>>,
  TError,
  { data: BodyType<AlarmInputDto> },
  TContext
> => {
  const mutationOptions = getPostUserAlarmMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 * @summary 알림 조회
 */
export const getUserAlarmFavoriteCodeTargetId = (
  favoriteCode: FavoriteCodeEnum,
  targetId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/alarm/${favoriteCode}/${targetId}`, method: 'GET', signal },
    options
  );
};

export const getGetUserAlarmFavoriteCodeTargetIdQueryKey = (
  favoriteCode: FavoriteCodeEnum,
  targetId: string
) => {
  return [`/user/alarm/${favoriteCode}/${targetId}`] as const;
};

export const getGetUserAlarmFavoriteCodeTargetIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAlarmFavoriteCodeTargetId>>,
  TError = ErrorType<unknown>
>(
  favoriteCode: FavoriteCodeEnum,
  targetId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserAlarmFavoriteCodeTargetId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserAlarmFavoriteCodeTargetIdQueryKey(favoriteCode, targetId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAlarmFavoriteCodeTargetId>>> = ({
    signal,
  }) => getUserAlarmFavoriteCodeTargetId(favoriteCode, targetId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(favoriteCode && targetId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAlarmFavoriteCodeTargetId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserAlarmFavoriteCodeTargetIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAlarmFavoriteCodeTargetId>>
>;
export type GetUserAlarmFavoriteCodeTargetIdQueryError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary 알림 조회
 */
export const useGetUserAlarmFavoriteCodeTargetId = <
  TData = Awaited<ReturnType<typeof getUserAlarmFavoriteCodeTargetId>>,
  TError = ErrorType<unknown>
>(
  favoriteCode: FavoriteCodeEnum,
  targetId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserAlarmFavoriteCodeTargetId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserAlarmFavoriteCodeTargetIdQueryOptions(
    favoriteCode,
    targetId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 알림 조회
 */
export const getUserFavoriteFavoriteCode = (
  favoriteCode: FavoriteCodeEnum,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/favorite/${favoriteCode}`, method: 'GET', signal },
    options
  );
};

export const getGetUserFavoriteFavoriteCodeQueryKey = (favoriteCode: FavoriteCodeEnum) => {
  return [`/user/favorite/${favoriteCode}`] as const;
};

export const getGetUserFavoriteFavoriteCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFavoriteFavoriteCode>>,
  TError = ErrorType<unknown>
>(
  favoriteCode: FavoriteCodeEnum,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFavoriteFavoriteCode>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFavoriteFavoriteCodeQueryKey(favoriteCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFavoriteFavoriteCode>>> = ({
    signal,
  }) => getUserFavoriteFavoriteCode(favoriteCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!favoriteCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFavoriteFavoriteCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFavoriteFavoriteCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFavoriteFavoriteCode>>
>;
export type GetUserFavoriteFavoriteCodeQueryError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary 알림 조회
 */
export const useGetUserFavoriteFavoriteCode = <
  TData = Awaited<ReturnType<typeof getUserFavoriteFavoriteCode>>,
  TError = ErrorType<unknown>
>(
  favoriteCode: FavoriteCodeEnum,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFavoriteFavoriteCode>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFavoriteFavoriteCodeQueryOptions(favoriteCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 단지에서 즐겨 찾기 클릭시
 */
export const getUserFavoriteDanjiSummaryDanjiId = (
  danjiId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<UserFavoriteDanjiSummaryDto>(
    { url: `/user/favorite-danji-summary/${danjiId}`, method: 'GET', signal },
    options
  );
};

export const getGetUserFavoriteDanjiSummaryDanjiIdQueryKey = (danjiId: string) => {
  return [`/user/favorite-danji-summary/${danjiId}`] as const;
};

export const getGetUserFavoriteDanjiSummaryDanjiIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFavoriteDanjiSummaryDanjiId>>,
  TError = ErrorType<unknown>
>(
  danjiId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserFavoriteDanjiSummaryDanjiId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFavoriteDanjiSummaryDanjiIdQueryKey(danjiId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFavoriteDanjiSummaryDanjiId>>> = ({
    signal,
  }) => getUserFavoriteDanjiSummaryDanjiId(danjiId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!danjiId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFavoriteDanjiSummaryDanjiId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFavoriteDanjiSummaryDanjiIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFavoriteDanjiSummaryDanjiId>>
>;
export type GetUserFavoriteDanjiSummaryDanjiIdQueryError = ErrorType<unknown>;

/**
 * @summary 단지에서 즐겨 찾기 클릭시
 */
export const useGetUserFavoriteDanjiSummaryDanjiId = <
  TData = Awaited<ReturnType<typeof getUserFavoriteDanjiSummaryDanjiId>>,
  TError = ErrorType<unknown>
>(
  danjiId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserFavoriteDanjiSummaryDanjiId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFavoriteDanjiSummaryDanjiIdQueryOptions(danjiId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 홈에서 사용하는 즐겨 찾기 리스트
마이 에서도 사용
 */
export const getUserFavoriteSummaryList = (
  params?: GetUserFavoriteSummaryListParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<FavoriteDanjiListDto>(
    { url: '/user/favorite-summary-list', method: 'GET', params, signal },
    options
  );
};

export const getGetUserFavoriteSummaryListQueryKey = (params?: GetUserFavoriteSummaryListParams) => {
  return ['/user/favorite-summary-list', ...(params ? [params] : [])] as const;
};

export const getGetUserFavoriteSummaryListQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFavoriteSummaryList>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserFavoriteSummaryListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFavoriteSummaryList>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFavoriteSummaryListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFavoriteSummaryList>>> = ({
    signal,
  }) => getUserFavoriteSummaryList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFavoriteSummaryList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFavoriteSummaryListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFavoriteSummaryList>>
>;
export type GetUserFavoriteSummaryListQueryError = ErrorType<unknown>;

/**
 * @summary 홈에서 사용하는 즐겨 찾기 리스트
마이 에서도 사용
 */
export const useGetUserFavoriteSummaryList = <
  TData = Awaited<ReturnType<typeof getUserFavoriteSummaryList>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserFavoriteSummaryListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFavoriteSummaryList>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFavoriteSummaryListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 홈 - 즐겨 찾기 리스트 (단지, 오피스텔, 경매 등등 종합으로 제공 )
 */
export const getUserFavoriteSummaryTotalList = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<FavoriteTotalDto>(
    { url: '/user/favorite-summary-total-list', method: 'GET', signal },
    options
  );
};

export const getGetUserFavoriteSummaryTotalListQueryKey = () => {
  return ['/user/favorite-summary-total-list'] as const;
};

export const getGetUserFavoriteSummaryTotalListQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFavoriteSummaryTotalList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFavoriteSummaryTotalList>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFavoriteSummaryTotalListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFavoriteSummaryTotalList>>> = ({
    signal,
  }) => getUserFavoriteSummaryTotalList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFavoriteSummaryTotalList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFavoriteSummaryTotalListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFavoriteSummaryTotalList>>
>;
export type GetUserFavoriteSummaryTotalListQueryError = ErrorType<unknown>;

/**
 * @summary 홈 - 즐겨 찾기 리스트 (단지, 오피스텔, 경매 등등 종합으로 제공 )
 */
export const useGetUserFavoriteSummaryTotalList = <
  TData = Awaited<ReturnType<typeof getUserFavoriteSummaryTotalList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFavoriteSummaryTotalList>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFavoriteSummaryTotalListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 삭제는 다 \\false 로 하고 넣어 주셔요
 * @summary 신규 즐겨 찾기 등록 / 수정 / 삭제
 */
export const postUserFavoriteDanjiSummary = (
  favoriteDanjiInputDto: BodyType<FavoriteDanjiInputDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/favorite-danji-summary',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: favoriteDanjiInputDto,
    },
    options
  );
};

export const getPostUserFavoriteDanjiSummaryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFavoriteDanjiSummary>>,
    TError,
    { data: BodyType<FavoriteDanjiInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserFavoriteDanjiSummary>>,
  TError,
  { data: BodyType<FavoriteDanjiInputDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserFavoriteDanjiSummary>>,
    { data: BodyType<FavoriteDanjiInputDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserFavoriteDanjiSummary(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserFavoriteDanjiSummaryMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserFavoriteDanjiSummary>>
>;
export type PostUserFavoriteDanjiSummaryMutationBody = BodyType<FavoriteDanjiInputDto>;
export type PostUserFavoriteDanjiSummaryMutationError = ErrorType<unknown>;

/**
 * @summary 신규 즐겨 찾기 등록 / 수정 / 삭제
 */
export const usePostUserFavoriteDanjiSummary = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFavoriteDanjiSummary>>,
    TError,
    { data: BodyType<FavoriteDanjiInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserFavoriteDanjiSummary>>,
  TError,
  { data: BodyType<FavoriteDanjiInputDto> },
  TContext
> => {
  const mutationOptions = getPostUserFavoriteDanjiSummaryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 * @summary 청약 즐겨 찾기 등록 / 삭제
 */
export const patchUserFavoriteParcelParcelDanjiIdFavoriteYn = (
  parcelDanjiId: string,
  favoriteYn: boolean,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/favorite-parcel/${parcelDanjiId}/${favoriteYn}`, method: 'PATCH' },
    options
  );
};

export const getPatchUserFavoriteParcelParcelDanjiIdFavoriteYnMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserFavoriteParcelParcelDanjiIdFavoriteYn>>,
    TError,
    { parcelDanjiId: string; favoriteYn: boolean },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUserFavoriteParcelParcelDanjiIdFavoriteYn>>,
  TError,
  { parcelDanjiId: string; favoriteYn: boolean },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUserFavoriteParcelParcelDanjiIdFavoriteYn>>,
    { parcelDanjiId: string; favoriteYn: boolean }
  > = (props) => {
    const { parcelDanjiId, favoriteYn } = props ?? {};

    return patchUserFavoriteParcelParcelDanjiIdFavoriteYn(parcelDanjiId, favoriteYn, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchUserFavoriteParcelParcelDanjiIdFavoriteYnMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUserFavoriteParcelParcelDanjiIdFavoriteYn>>
>;

export type PatchUserFavoriteParcelParcelDanjiIdFavoriteYnMutationError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary 청약 즐겨 찾기 등록 / 삭제
 */
export const usePatchUserFavoriteParcelParcelDanjiIdFavoriteYn = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserFavoriteParcelParcelDanjiIdFavoriteYn>>,
    TError,
    { parcelDanjiId: string; favoriteYn: boolean },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchUserFavoriteParcelParcelDanjiIdFavoriteYn>>,
  TError,
  { parcelDanjiId: string; favoriteYn: boolean },
  TContext
> => {
  const mutationOptions = getPatchUserFavoriteParcelParcelDanjiIdFavoriteYnMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 즐겨 찾기 등록
 */
export const putUserFavoriteAuctionAuctionId = (
  auctionId: string,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/favorite-auction/${auctionId}`, method: 'PUT' },
    options
  );
};

export const getPutUserFavoriteAuctionAuctionIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserFavoriteAuctionAuctionId>>,
    TError,
    { auctionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserFavoriteAuctionAuctionId>>,
  TError,
  { auctionId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserFavoriteAuctionAuctionId>>,
    { auctionId: string }
  > = (props) => {
    const { auctionId } = props ?? {};

    return putUserFavoriteAuctionAuctionId(auctionId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserFavoriteAuctionAuctionIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserFavoriteAuctionAuctionId>>
>;

export type PutUserFavoriteAuctionAuctionIdMutationError = ErrorType<unknown>;

/**
 * @summary 경매 즐겨 찾기 등록
 */
export const usePutUserFavoriteAuctionAuctionId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserFavoriteAuctionAuctionId>>,
    TError,
    { auctionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserFavoriteAuctionAuctionId>>,
  TError,
  { auctionId: string },
  TContext
> => {
  const mutationOptions = getPutUserFavoriteAuctionAuctionIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 경매 즐겨 찾기 등록
 */
export const deleteUserFavoriteAuctionAuctionId = (
  auctionId: string,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/favorite-auction/${auctionId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteUserFavoriteAuctionAuctionIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFavoriteAuctionAuctionId>>,
    TError,
    { auctionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserFavoriteAuctionAuctionId>>,
  TError,
  { auctionId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserFavoriteAuctionAuctionId>>,
    { auctionId: string }
  > = (props) => {
    const { auctionId } = props ?? {};

    return deleteUserFavoriteAuctionAuctionId(auctionId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserFavoriteAuctionAuctionIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserFavoriteAuctionAuctionId>>
>;

export type DeleteUserFavoriteAuctionAuctionIdMutationError = ErrorType<unknown>;

/**
 * @summary 경매 즐겨 찾기 등록
 */
export const useDeleteUserFavoriteAuctionAuctionId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFavoriteAuctionAuctionId>>,
    TError,
    { auctionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserFavoriteAuctionAuctionId>>,
  TError,
  { auctionId: string },
  TContext
> => {
  const mutationOptions = getDeleteUserFavoriteAuctionAuctionIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 홈 피드 단지 추천 목록
 */
export const getUserFeedRecommend = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<FeedSubscriptionDto>(
    { url: '/user/feed/recommend', method: 'GET', signal },
    options
  );
};

export const getGetUserFeedRecommendQueryKey = () => {
  return ['/user/feed/recommend'] as const;
};

export const getGetUserFeedRecommendQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFeedRecommend>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFeedRecommend>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFeedRecommendQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFeedRecommend>>> = ({ signal }) =>
    getUserFeedRecommend(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFeedRecommend>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFeedRecommendQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFeedRecommend>>
>;
export type GetUserFeedRecommendQueryError = ErrorType<unknown>;

/**
 * @summary 홈 피드 단지 추천 목록
 */
export const useGetUserFeedRecommend = <
  TData = Awaited<ReturnType<typeof getUserFeedRecommend>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFeedRecommend>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFeedRecommendQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 홈 피드 구독 목록(추천도 포함됨 플래그로 구분)
 */
export const getUserFeedSubscription = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<FeedSubscriptionDto>(
    { url: '/user/feed/subscription', method: 'GET', signal },
    options
  );
};

export const getGetUserFeedSubscriptionQueryKey = () => {
  return ['/user/feed/subscription'] as const;
};

export const getGetUserFeedSubscriptionQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFeedSubscription>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFeedSubscription>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFeedSubscriptionQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFeedSubscription>>> = ({ signal }) =>
    getUserFeedSubscription(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFeedSubscription>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFeedSubscriptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFeedSubscription>>
>;
export type GetUserFeedSubscriptionQueryError = ErrorType<unknown>;

/**
 * @summary 홈 피드 구독 목록(추천도 포함됨 플래그로 구분)
 */
export const useGetUserFeedSubscription = <
  TData = Awaited<ReturnType<typeof getUserFeedSubscription>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFeedSubscription>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFeedSubscriptionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 홈 피드 구독 추가
 */
export const postUserFeedSubscription = (
  feedSubscriptionReqDto: BodyType<FeedSubscriptionReqDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<FeedSubscriptionDto>(
    {
      url: '/user/feed/subscription',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: feedSubscriptionReqDto,
    },
    options
  );
};

export const getPostUserFeedSubscriptionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFeedSubscription>>,
    TError,
    { data: BodyType<FeedSubscriptionReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserFeedSubscription>>,
  TError,
  { data: BodyType<FeedSubscriptionReqDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserFeedSubscription>>,
    { data: BodyType<FeedSubscriptionReqDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserFeedSubscription(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserFeedSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserFeedSubscription>>
>;
export type PostUserFeedSubscriptionMutationBody = BodyType<FeedSubscriptionReqDto>;
export type PostUserFeedSubscriptionMutationError = ErrorType<unknown>;

/**
 * @summary 홈 피드 구독 추가
 */
export const usePostUserFeedSubscription = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFeedSubscription>>,
    TError,
    { data: BodyType<FeedSubscriptionReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserFeedSubscription>>,
  TError,
  { data: BodyType<FeedSubscriptionReqDto> },
  TContext
> => {
  const mutationOptions = getPostUserFeedSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 홈 피드 구독 삭제
 */
export const deleteUserFeedSubscription = (
  feedSubscriptionReqDto: BodyType<FeedSubscriptionReqDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<FeedSubscriptionDto>(
    {
      url: '/user/feed/subscription',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: feedSubscriptionReqDto,
    },
    options
  );
};

export const getDeleteUserFeedSubscriptionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFeedSubscription>>,
    TError,
    { data: BodyType<FeedSubscriptionReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserFeedSubscription>>,
  TError,
  { data: BodyType<FeedSubscriptionReqDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserFeedSubscription>>,
    { data: BodyType<FeedSubscriptionReqDto> }
  > = (props) => {
    const { data } = props ?? {};

    return deleteUserFeedSubscription(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserFeedSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserFeedSubscription>>
>;
export type DeleteUserFeedSubscriptionMutationBody = BodyType<FeedSubscriptionReqDto>;
export type DeleteUserFeedSubscriptionMutationError = ErrorType<unknown>;

/**
 * @summary 홈 피드 구독 삭제
 */
export const useDeleteUserFeedSubscription = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFeedSubscription>>,
    TError,
    { data: BodyType<FeedSubscriptionReqDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserFeedSubscription>>,
  TError,
  { data: BodyType<FeedSubscriptionReqDto> },
  TContext
> => {
  const mutationOptions = getDeleteUserFeedSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 홈 피드 알람 설정 정보 얻기
 */
export const getUserFeedAlarm = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<FeedAlarmDto[]>({ url: '/user/feed/alarm', method: 'GET', signal }, options);
};

export const getGetUserFeedAlarmQueryKey = () => {
  return ['/user/feed/alarm'] as const;
};

export const getGetUserFeedAlarmQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFeedAlarm>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFeedAlarm>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFeedAlarmQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFeedAlarm>>> = ({ signal }) =>
    getUserFeedAlarm(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFeedAlarm>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFeedAlarmQueryResult = NonNullable<Awaited<ReturnType<typeof getUserFeedAlarm>>>;
export type GetUserFeedAlarmQueryError = ErrorType<unknown>;

/**
 * @summary 홈 피드 알람 설정 정보 얻기
 */
export const useGetUserFeedAlarm = <
  TData = Awaited<ReturnType<typeof getUserFeedAlarm>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFeedAlarm>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFeedAlarmQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 홈 피드 알람 설정 업데이트
 */
export const postUserFeedAlarm = (
  feedAlarmDto: BodyType<FeedAlarmDto[]>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<FeedAlarmDto[]>(
    {
      url: '/user/feed/alarm',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: feedAlarmDto,
    },
    options
  );
};

export const getPostUserFeedAlarmMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFeedAlarm>>,
    TError,
    { data: BodyType<FeedAlarmDto[]> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserFeedAlarm>>,
  TError,
  { data: BodyType<FeedAlarmDto[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserFeedAlarm>>,
    { data: BodyType<FeedAlarmDto[]> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserFeedAlarm(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserFeedAlarmMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserFeedAlarm>>
>;
export type PostUserFeedAlarmMutationBody = BodyType<FeedAlarmDto[]>;
export type PostUserFeedAlarmMutationError = ErrorType<unknown>;

/**
 * @summary 홈 피드 알람 설정 업데이트
 */
export const usePostUserFeedAlarm = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFeedAlarm>>,
    TError,
    { data: BodyType<FeedAlarmDto[]> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserFeedAlarm>>,
  TError,
  { data: BodyType<FeedAlarmDto[]> },
  TContext
> => {
  const mutationOptions = getPostUserFeedAlarmMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 피드 유저 액션 기록
 */
export const postUserFeedActivity = (
  feedActivityDto: BodyType<FeedActivityDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/feed/activity',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: feedActivityDto,
    },
    options
  );
};

export const getPostUserFeedActivityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFeedActivity>>,
    TError,
    { data: BodyType<FeedActivityDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserFeedActivity>>,
  TError,
  { data: BodyType<FeedActivityDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserFeedActivity>>,
    { data: BodyType<FeedActivityDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserFeedActivity(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserFeedActivityMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserFeedActivity>>
>;
export type PostUserFeedActivityMutationBody = BodyType<FeedActivityDto>;
export type PostUserFeedActivityMutationError = ErrorType<unknown>;

/**
 * @summary 피드 유저 액션 기록
 */
export const usePostUserFeedActivity = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserFeedActivity>>,
    TError,
    { data: BodyType<FeedActivityDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserFeedActivity>>,
  TError,
  { data: BodyType<FeedActivityDto> },
  TContext
> => {
  const mutationOptions = getPostUserFeedActivityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserKakaoChannel = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>({ url: '/user/kakao/channel', method: 'GET', signal }, options);
};

export const getGetUserKakaoChannelQueryKey = () => {
  return ['/user/kakao/channel'] as const;
};

export const getGetUserKakaoChannelQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserKakaoChannel>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserKakaoChannel>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserKakaoChannelQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserKakaoChannel>>> = ({ signal }) =>
    getUserKakaoChannel(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserKakaoChannel>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserKakaoChannelQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserKakaoChannel>>
>;
export type GetUserKakaoChannelQueryError = ErrorType<unknown>;

export const useGetUserKakaoChannel = <
  TData = Awaited<ReturnType<typeof getUserKakaoChannel>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserKakaoChannel>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserKakaoChannelQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const getUserFeedsNewsContentsBjdCode = (
  bjdCode: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/feeds/newsContents/${bjdCode}`, method: 'GET', signal },
    options
  );
};

export const getGetUserFeedsNewsContentsBjdCodeQueryKey = (bjdCode: string) => {
  return [`/user/feeds/newsContents/${bjdCode}`] as const;
};

export const getGetUserFeedsNewsContentsBjdCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFeedsNewsContentsBjdCode>>,
  TError = ErrorType<unknown>
>(
  bjdCode: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserFeedsNewsContentsBjdCode>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFeedsNewsContentsBjdCodeQueryKey(bjdCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFeedsNewsContentsBjdCode>>> = ({
    signal,
  }) => getUserFeedsNewsContentsBjdCode(bjdCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!bjdCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFeedsNewsContentsBjdCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFeedsNewsContentsBjdCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFeedsNewsContentsBjdCode>>
>;
export type GetUserFeedsNewsContentsBjdCodeQueryError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const useGetUserFeedsNewsContentsBjdCode = <
  TData = Awaited<ReturnType<typeof getUserFeedsNewsContentsBjdCode>>,
  TError = ErrorType<unknown>
>(
  bjdCode: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserFeedsNewsContentsBjdCode>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFeedsNewsContentsBjdCodeQueryOptions(bjdCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postUserNhToken = (
  nhRequestDto: BodyType<NhRequestDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<NhResponseDto>(
    {
      url: '/user/nh/token',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: nhRequestDto,
    },
    options
  );
};

export const getPostUserNhTokenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhToken>>,
    TError,
    { data: BodyType<NhRequestDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserNhToken>>,
  TError,
  { data: BodyType<NhRequestDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserNhToken>>,
    { data: BodyType<NhRequestDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserNhToken(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserNhTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postUserNhToken>>>;
export type PostUserNhTokenMutationBody = BodyType<NhRequestDto>;
export type PostUserNhTokenMutationError = ErrorType<unknown>;

export const usePostUserNhToken = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhToken>>,
    TError,
    { data: BodyType<NhRequestDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserNhToken>>,
  TError,
  { data: BodyType<NhRequestDto> },
  TContext
> => {
  const mutationOptions = getPostUserNhTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postUserNhFavorite = (
  nhRequestDto: BodyType<NhRequestDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<NhResponseDto>(
    {
      url: '/user/nh/favorite',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: nhRequestDto,
    },
    options
  );
};

export const getPostUserNhFavoriteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhFavorite>>,
    TError,
    { data: BodyType<NhRequestDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserNhFavorite>>,
  TError,
  { data: BodyType<NhRequestDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserNhFavorite>>,
    { data: BodyType<NhRequestDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserNhFavorite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserNhFavoriteMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserNhFavorite>>
>;
export type PostUserNhFavoriteMutationBody = BodyType<NhRequestDto>;
export type PostUserNhFavoriteMutationError = ErrorType<unknown>;

export const usePostUserNhFavorite = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhFavorite>>,
    TError,
    { data: BodyType<NhRequestDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserNhFavorite>>,
  TError,
  { data: BodyType<NhRequestDto> },
  TContext
> => {
  const mutationOptions = getPostUserNhFavoriteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postUserNhWithdraw = (
  nhRequestDto: BodyType<NhRequestDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<NhResponseDto>(
    {
      url: '/user/nh/withdraw',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: nhRequestDto,
    },
    options
  );
};

export const getPostUserNhWithdrawMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhWithdraw>>,
    TError,
    { data: BodyType<NhRequestDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserNhWithdraw>>,
  TError,
  { data: BodyType<NhRequestDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserNhWithdraw>>,
    { data: BodyType<NhRequestDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserNhWithdraw(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserNhWithdrawMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserNhWithdraw>>
>;
export type PostUserNhWithdrawMutationBody = BodyType<NhRequestDto>;
export type PostUserNhWithdrawMutationError = ErrorType<unknown>;

export const usePostUserNhWithdraw = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhWithdraw>>,
    TError,
    { data: BodyType<NhRequestDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserNhWithdraw>>,
  TError,
  { data: BodyType<NhRequestDto> },
  TContext
> => {
  const mutationOptions = getPostUserNhWithdrawMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postUserNhWebview = (
  nhRequestDto: BodyType<NhRequestDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/nh/webview',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: nhRequestDto,
    },
    options
  );
};

export const getPostUserNhWebviewMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhWebview>>,
    TError,
    { data: BodyType<NhRequestDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserNhWebview>>,
  TError,
  { data: BodyType<NhRequestDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserNhWebview>>,
    { data: BodyType<NhRequestDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserNhWebview(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserNhWebviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserNhWebview>>
>;
export type PostUserNhWebviewMutationBody = BodyType<NhRequestDto>;
export type PostUserNhWebviewMutationError = ErrorType<unknown>;

export const usePostUserNhWebview = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhWebview>>,
    TError,
    { data: BodyType<NhRequestDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserNhWebview>>,
  TError,
  { data: BodyType<NhRequestDto> },
  TContext
> => {
  const mutationOptions = getPostUserNhWebviewMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserNhInternalToken = (
  params?: GetUserNhInternalTokenParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: '/user/nh/internal/token', method: 'GET', params, signal },
    options
  );
};

export const getGetUserNhInternalTokenQueryKey = (params?: GetUserNhInternalTokenParams) => {
  return ['/user/nh/internal/token', ...(params ? [params] : [])] as const;
};

export const getGetUserNhInternalTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserNhInternalToken>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNhInternalTokenParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNhInternalToken>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNhInternalTokenQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNhInternalToken>>> = ({ signal }) =>
    getUserNhInternalToken(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserNhInternalToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserNhInternalTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserNhInternalToken>>
>;
export type GetUserNhInternalTokenQueryError = ErrorType<unknown>;

export const useGetUserNhInternalToken = <
  TData = Awaited<ReturnType<typeof getUserNhInternalToken>>,
  TError = ErrorType<unknown>
>(
  params?: GetUserNhInternalTokenParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNhInternalToken>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserNhInternalTokenQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postUserNhInternalFavorite = (
  insertNhFavoriteInputDto: BodyType<InsertNhFavoriteInputDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/nh/internal/favorite',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: insertNhFavoriteInputDto,
    },
    options
  );
};

export const getPostUserNhInternalFavoriteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhInternalFavorite>>,
    TError,
    { data: BodyType<InsertNhFavoriteInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserNhInternalFavorite>>,
  TError,
  { data: BodyType<InsertNhFavoriteInputDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserNhInternalFavorite>>,
    { data: BodyType<InsertNhFavoriteInputDto> }
  > = (props) => {
    const { data } = props ?? {};

    return postUserNhInternalFavorite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserNhInternalFavoriteMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserNhInternalFavorite>>
>;
export type PostUserNhInternalFavoriteMutationBody = BodyType<InsertNhFavoriteInputDto>;
export type PostUserNhInternalFavoriteMutationError = ErrorType<unknown>;

export const usePostUserNhInternalFavorite = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserNhInternalFavorite>>,
    TError,
    { data: BodyType<InsertNhFavoriteInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserNhInternalFavorite>>,
  TError,
  { data: BodyType<InsertNhFavoriteInputDto> },
  TContext
> => {
  const mutationOptions = getPostUserNhInternalFavoriteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteUserNhInternalFavorite = (
  deleteNhFavoriteInputDto: BodyType<DeleteNhFavoriteInputDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/nh/internal/favorite',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: deleteNhFavoriteInputDto,
    },
    options
  );
};

export const getDeleteUserNhInternalFavoriteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserNhInternalFavorite>>,
    TError,
    { data: BodyType<DeleteNhFavoriteInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserNhInternalFavorite>>,
  TError,
  { data: BodyType<DeleteNhFavoriteInputDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserNhInternalFavorite>>,
    { data: BodyType<DeleteNhFavoriteInputDto> }
  > = (props) => {
    const { data } = props ?? {};

    return deleteUserNhInternalFavorite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserNhInternalFavoriteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserNhInternalFavorite>>
>;
export type DeleteUserNhInternalFavoriteMutationBody = BodyType<DeleteNhFavoriteInputDto>;
export type DeleteUserNhInternalFavoriteMutationError = ErrorType<unknown>;

export const useDeleteUserNhInternalFavorite = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserNhInternalFavorite>>,
    TError,
    { data: BodyType<DeleteNhFavoriteInputDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserNhInternalFavorite>>,
  TError,
  { data: BodyType<DeleteNhFavoriteInputDto> },
  TContext
> => {
  const mutationOptions = getDeleteUserNhInternalFavoriteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserNhInternalFavorite = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<NhFavoritesDto>(
    { url: '/user/nh/internal/favorite', method: 'GET', signal },
    options
  );
};

export const getGetUserNhInternalFavoriteQueryKey = () => {
  return ['/user/nh/internal/favorite'] as const;
};

export const getGetUserNhInternalFavoriteQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserNhInternalFavorite>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNhInternalFavorite>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNhInternalFavoriteQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNhInternalFavorite>>> = ({
    signal,
  }) => getUserNhInternalFavorite(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserNhInternalFavorite>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserNhInternalFavoriteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserNhInternalFavorite>>
>;
export type GetUserNhInternalFavoriteQueryError = ErrorType<unknown>;

export const useGetUserNhInternalFavorite = <
  TData = Awaited<ReturnType<typeof getUserNhInternalFavorite>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserNhInternalFavorite>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserNhInternalFavoriteQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const putUserNicknameNickname = (
  nickname: string,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>({ url: `/user/nickname/${nickname}`, method: 'PUT' }, options);
};

export const getPutUserNicknameNicknameMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserNicknameNickname>>,
    TError,
    { nickname: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserNicknameNickname>>,
  TError,
  { nickname: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserNicknameNickname>>,
    { nickname: string }
  > = (props) => {
    const { nickname } = props ?? {};

    return putUserNicknameNickname(nickname, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserNicknameNicknameMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserNicknameNickname>>
>;

export type PutUserNicknameNicknameMutationError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const usePutUserNicknameNickname = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserNicknameNickname>>,
    TError,
    { nickname: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserNicknameNickname>>,
  TError,
  { nickname: string },
  TContext
> => {
  const mutationOptions = getPutUserNicknameNicknameMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putUserEmailEmail = (email: string, options?: SecondParameter<typeof customFetch>) => {
  return customFetch<ApiResponse>({ url: `/user/email/${email}`, method: 'PUT' }, options);
};

export const getPutUserEmailEmailMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserEmailEmail>>,
    TError,
    { email: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserEmailEmail>>,
  TError,
  { email: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserEmailEmail>>,
    { email: string }
  > = (props) => {
    const { email } = props ?? {};

    return putUserEmailEmail(email, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserEmailEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserEmailEmail>>
>;

export type PutUserEmailEmailMutationError = ErrorType<unknown>;

export const usePutUserEmailEmail = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserEmailEmail>>,
    TError,
    { email: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserEmailEmail>>,
  TError,
  { email: string },
  TContext
> => {
  const mutationOptions = getPutUserEmailEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const putUserRealestateInfo = (
  params?: PutUserRealestateInfoParams,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>({ url: '/user/realestate/info', method: 'PUT', params }, options);
};

export const getPutUserRealestateInfoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserRealestateInfo>>,
    TError,
    { params?: PutUserRealestateInfoParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserRealestateInfo>>,
  TError,
  { params?: PutUserRealestateInfoParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserRealestateInfo>>,
    { params?: PutUserRealestateInfoParams }
  > = (props) => {
    const { params } = props ?? {};

    return putUserRealestateInfo(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserRealestateInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserRealestateInfo>>
>;

export type PutUserRealestateInfoMutationError = ErrorType<unknown>;

/**
 * @deprecated
 */
export const usePutUserRealestateInfo = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserRealestateInfo>>,
    TError,
    { params?: PutUserRealestateInfoParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserRealestateInfo>>,
  TError,
  { params?: PutUserRealestateInfoParams },
  TContext
> => {
  const mutationOptions = getPutUserRealestateInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 *  ErrorCode :  (105, Input Data Error) //  (106, Duplicated data)
 * @summary 유저 정보   수정 - 유저기본정보 , 커뮤니티 정보
 */
export const patchUserUserInfo = (
  updateUserDto: BodyType<UpdateUserDto>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/user/user-info',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateUserDto,
    },
    options
  );
};

export const getPatchUserUserInfoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserUserInfo>>,
    TError,
    { data: BodyType<UpdateUserDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUserUserInfo>>,
  TError,
  { data: BodyType<UpdateUserDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUserUserInfo>>,
    { data: BodyType<UpdateUserDto> }
  > = (props) => {
    const { data } = props ?? {};

    return patchUserUserInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchUserUserInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUserUserInfo>>
>;
export type PatchUserUserInfoMutationBody = BodyType<UpdateUserDto>;
export type PatchUserUserInfoMutationError = ErrorType<unknown>;

/**
 * @summary 유저 정보   수정 - 유저기본정보 , 커뮤니티 정보
 */
export const usePatchUserUserInfo = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserUserInfo>>,
    TError,
    { data: BodyType<UpdateUserDto> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchUserUserInfo>>,
  TError,
  { data: BodyType<UpdateUserDto> },
  TContext
> => {
  const mutationOptions = getPatchUserUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 *  ErrorCode :  (106, Duplicated data)
 * @summary 닉네임 중복 체크
 */
export const getUserCheckNicknameNickname = (
  nickname: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/check-nickname/${nickname}`, method: 'GET', signal },
    options
  );
};

export const getGetUserCheckNicknameNicknameQueryKey = (nickname: string) => {
  return [`/user/check-nickname/${nickname}`] as const;
};

export const getGetUserCheckNicknameNicknameQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserCheckNicknameNickname>>,
  TError = ErrorType<unknown>
>(
  nickname: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserCheckNicknameNickname>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserCheckNicknameNicknameQueryKey(nickname);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCheckNicknameNickname>>> = ({
    signal,
  }) => getUserCheckNicknameNickname(nickname, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!nickname, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserCheckNicknameNickname>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserCheckNicknameNicknameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserCheckNicknameNickname>>
>;
export type GetUserCheckNicknameNicknameQueryError = ErrorType<unknown>;

/**
 * @summary 닉네임 중복 체크
 */
export const useGetUserCheckNicknameNickname = <
  TData = Awaited<ReturnType<typeof getUserCheckNicknameNickname>>,
  TError = ErrorType<unknown>
>(
  nickname: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserCheckNicknameNickname>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserCheckNicknameNicknameQueryOptions(nickname, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 *  Error : 105, 입력 데이터에 이상이 있습니다. // 109, 수정 할수 없는 데이터 입니다.
 * @summary 정책 변경
 */
export const patchUserTermsTermNameAgreeYn = (
  termName: string,
  agreeYn: boolean,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/terms/${termName}/${agreeYn}`, method: 'PATCH' },
    options
  );
};

export const getPatchUserTermsTermNameAgreeYnMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserTermsTermNameAgreeYn>>,
    TError,
    { termName: string; agreeYn: boolean },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUserTermsTermNameAgreeYn>>,
  TError,
  { termName: string; agreeYn: boolean },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUserTermsTermNameAgreeYn>>,
    { termName: string; agreeYn: boolean }
  > = (props) => {
    const { termName, agreeYn } = props ?? {};

    return patchUserTermsTermNameAgreeYn(termName, agreeYn, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchUserTermsTermNameAgreeYnMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUserTermsTermNameAgreeYn>>
>;

export type PatchUserTermsTermNameAgreeYnMutationError = ErrorType<unknown>;

/**
 * @summary 정책 변경
 */
export const usePatchUserTermsTermNameAgreeYn = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserTermsTermNameAgreeYn>>,
    TError,
    { termName: string; agreeYn: boolean },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchUserTermsTermNameAgreeYn>>,
  TError,
  { termName: string; agreeYn: boolean },
  TContext
> => {
  const mutationOptions = getPatchUserTermsTermNameAgreeYnMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 정책 동의여부
 */
export const getUserTermsTermName = (
  termName: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>({ url: `/user/terms/${termName}`, method: 'GET', signal }, options);
};

export const getGetUserTermsTermNameQueryKey = (termName: string) => {
  return [`/user/terms/${termName}`] as const;
};

export const getGetUserTermsTermNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserTermsTermName>>,
  TError = ErrorType<unknown>
>(
  termName: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserTermsTermName>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserTermsTermNameQueryKey(termName);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserTermsTermName>>> = ({ signal }) =>
    getUserTermsTermName(termName, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!termName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserTermsTermName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserTermsTermNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserTermsTermName>>
>;
export type GetUserTermsTermNameQueryError = ErrorType<unknown>;

/**
 * @summary 정책 동의여부
 */
export const useGetUserTermsTermName = <
  TData = Awaited<ReturnType<typeof getUserTermsTermName>>,
  TError = ErrorType<unknown>
>(
  termName: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserTermsTermName>>, TError, TData>;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserTermsTermNameQueryOptions(termName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 *
 * @deprecated
 * @summary 내가 적은 글 조회
 */
export const getUserCommunityListListSizeLatestId = (
  listSize: number,
  latestId: number,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>(
    { url: `/user/communityList/${listSize}/${latestId}`, method: 'GET', signal },
    options
  );
};

export const getGetUserCommunityListListSizeLatestIdQueryKey = (
  listSize: number,
  latestId: number
) => {
  return [`/user/communityList/${listSize}/${latestId}`] as const;
};

export const getGetUserCommunityListListSizeLatestIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserCommunityListListSizeLatestId>>,
  TError = ErrorType<unknown>
>(
  listSize: number,
  latestId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserCommunityListListSizeLatestId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserCommunityListListSizeLatestIdQueryKey(listSize, latestId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCommunityListListSizeLatestId>>> = ({
    signal,
  }) => getUserCommunityListListSizeLatestId(listSize, latestId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(listSize && latestId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserCommunityListListSizeLatestId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserCommunityListListSizeLatestIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserCommunityListListSizeLatestId>>
>;
export type GetUserCommunityListListSizeLatestIdQueryError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary 내가 적은 글 조회
 */
export const useGetUserCommunityListListSizeLatestId = <
  TData = Awaited<ReturnType<typeof getUserCommunityListListSizeLatestId>>,
  TError = ErrorType<unknown>
>(
  listSize: number,
  latestId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserCommunityListListSizeLatestId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customFetch>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserCommunityListListSizeLatestIdQueryOptions(
    listSize,
    latestId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 1.0 에서 넘어 오는 유저중 신규 동의 받을 경우
 */
export const postUserTermsR2Agree = (options?: SecondParameter<typeof customFetch>) => {
  return customFetch<ApiResponse>({ url: '/user/terms/R2Agree', method: 'POST' }, options);
};

export const getPostUserTermsR2AgreeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserTermsR2Agree>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<Awaited<ReturnType<typeof postUserTermsR2Agree>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserTermsR2Agree>>, void> = () => {
    return postUserTermsR2Agree(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserTermsR2AgreeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserTermsR2Agree>>
>;

export type PostUserTermsR2AgreeMutationError = ErrorType<unknown>;

/**
 * @summary 1.0 에서 넘어 오는 유저중 신규 동의 받을 경우
 */
export const usePostUserTermsR2Agree = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserTermsR2Agree>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<Awaited<ReturnType<typeof postUserTermsR2Agree>>, TError, void, TContext> => {
  const mutationOptions = getPostUserTermsR2AgreeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 * @summary 마케팅 동의 여부 마지막 업데이트 날짜 정보
 */
export const getUserTermsMarketing = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal
) => {
  return customFetch<ApiResponse>({ url: '/user/terms/marketing', method: 'GET', signal }, options);
};

export const getGetUserTermsMarketingQueryKey = () => {
  return ['/user/terms/marketing'] as const;
};

export const getGetUserTermsMarketingQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserTermsMarketing>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserTermsMarketing>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserTermsMarketingQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserTermsMarketing>>> = ({ signal }) =>
    getUserTermsMarketing(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserTermsMarketing>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserTermsMarketingQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserTermsMarketing>>
>;
export type GetUserTermsMarketingQueryError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary 마케팅 동의 여부 마지막 업데이트 날짜 정보
 */
export const useGetUserTermsMarketing = <
  TData = Awaited<ReturnType<typeof getUserTermsMarketing>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserTermsMarketing>>, TError, TData>;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserTermsMarketingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postUserMobileTokenToken = (
  token: string,
  params?: PostUserMobileTokenTokenParams,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    { url: `/user/mobile-token/${token}`, method: 'POST', params },
    options
  );
};

export const getPostUserMobileTokenTokenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserMobileTokenToken>>,
    TError,
    { token: string; params?: PostUserMobileTokenTokenParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserMobileTokenToken>>,
  TError,
  { token: string; params?: PostUserMobileTokenTokenParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserMobileTokenToken>>,
    { token: string; params?: PostUserMobileTokenTokenParams }
  > = (props) => {
    const { token, params } = props ?? {};

    return postUserMobileTokenToken(token, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserMobileTokenTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUserMobileTokenToken>>
>;

export type PostUserMobileTokenTokenMutationError = ErrorType<unknown>;

export const usePostUserMobileTokenToken = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserMobileTokenToken>>,
    TError,
    { token: string; params?: PostUserMobileTokenTokenParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserMobileTokenToken>>,
  TError,
  { token: string; params?: PostUserMobileTokenTokenParams },
  TContext
> => {
  const mutationOptions = getPostUserMobileTokenTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
